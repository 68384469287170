<template>
    <div>
        <div class="flex flex-wrap gap-3" v-if="!this.submitted">
            <div class="h-auto min-h-fit rounded-lg p-3 flex-grow">
                <div @drop.prevent="dropFile" @dragenter="dropFile" @dragover="dropFile"
                    :class="{ ' bg-pink-800': dragActive }" style="height: 280px"
                    class="flex items-center justify-center w-full px-12 py-8 border-4 border-gray-300 border-dashed rounded dark:border-2">
                    <div
                        class="flex flex-col items-center justify-center gap-1 w-full text-white dark:text-gray-100 py-2">
                        <label for="file"
                            class="w-auto flex items-center hover:bg-gray-900 bg-red-500 px-5 py-2 
                             rounded-full shadow-lg border cursor-pointer text-white my-auto font-medium text-[22px] gap-2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24"
                                fill="none" data-v-5d608623="">
                                <g clip-path="url(#clip0_429_10970)" data-v-5d608623="">
                                    <circle cx="12" cy="11.999" r="9" stroke="#FFFFFF" stroke-width="2.5"
                                        stroke-linecap="round" stroke-linejoin="round" data-v-5d608623=""></circle>
                                    <path d="M12 9V15" stroke="#FFFFFF" stroke-width="2.5" stroke-linecap="round"
                                        stroke-linejoin="round" data-v-5d608623=""></path>
                                    <path d="M9 12H15" stroke="#FFFFFF" stroke-width="2.5" stroke-linecap="round"
                                        stroke-linejoin="round" data-v-5d608623=""></path>
                                </g>
                                <defs data-v-5d608623="">
                                    <clipPath id="clip0_429_10970" data-v-5d608623="">
                                        <rect width="24" height="24" fill="white" data-v-5d608623=""></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                            <span class="">Upload your JPG Files</span>
                            <input type="file" multiple name="fields" @change="handleFileSelection" ref="file"
                                accept=".jpg" id="file" class="hidden" />
                        </label>
                        <p class="text-base md:text-xl mt-2.5 text-gray-400 font-semibold">
                            Or drop files here.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex flex-col lg:flex-row w-full" v-if="this.files.length && !this.submitted">
            <div class="flex flex-col lg:flex-row w-full lg:space-x-4">
                <!-- First Section: Files List -->
                <div class="w-full lg:w-1/2 p-4 rounded-lg mb-4 lg:mb-0">
                    <div class="flex flex-col sm:flex-row justify-between mb-4">
                        <h3 class="text-lg font-semibold text-gray-900">
                            Images ({{ this.files.length }})
                        </h3>
                        <div class="flex items-center space-x-2">
                            <!-- Format Selector -->
                            <label for="format" class="mb-4 mt-2 font-medium text-gray-700">Save as :</label>
                            <select v-model="selectedFormat"
                                class="py-2 px-4 mb-4 w-20 text-sm font-medium text-gray-700 bg-white border rounded-md focus:ring-2 focus:ring-red-300">
                                <option value="jpg">JPG</option>
                                <option value="png">PNG</option>
                                <option value="gif">Gif</option>
                                <option value="pdf">PDF</option>
                            </select>
                            <button @click="mergeAllImages" :disabled="files.length === 0" v-if="files.length"
                                type="button" class="py-3 px-6 mb-4 text-sm text-center font-medium text-white rounded-md bg-red-600 hover:bg-red-500
                             disabled:bg-gray-300 disabled:cursor-not-allowed focus:outline-none focus:ring-4 focus:ring-red-300 
                             flex items-center justify-center space-x-3 transition duration-150 ease-in-out">
                                <svg class="w-6 h-6 text-white dark:text-white" aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none"
                                    viewBox="0 0 24 24">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="2"
                                        d="M12 13V4M7 14H5a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1h-2m-1-5-4 5-4-5m9 8h.01" />
                                </svg>
                                <span>Download </span>
                            </button>
                        </div>
                    </div>

                    <!-- Files Display -->
                    <div class="space-y-4">
                        <div v-for="(file, index) in files" :key="index"
                            class="flex justify-between items-center border p-3 rounded-md shadow-sm bg-gray-50">
                            <div class="flex items-center space-x-4">
                                <img :src="file.dataURL" alt="File image" class="w-16 h-16 object-cover rounded-md" />
                                <div class="text-sm hidden sm:block">
                                    <p class="font-semibold text-gray-700">
                                        {{
                                            file.name.substr(
                                                file.name.length - 10
                                            )
                                        }}
                                    </p>
                                    <p class="text-gray-500">
                                        {{
                                            Math.floor(file.file.size / 1024)
                                        }}
                                        KB
                                    </p>
                                </div>
                            </div>
                            <!-- Output Selection and Actions -->
                            <div class="flex items-center space-x-4">
                                <button @click="CropperPopupSet(index)" title="Rotate Left"
                                    class="inline-flex items-center top-1 right-1 my-auto">
                                    <div
                                        class="my-auto mx-2 bg-[#F5F7F9] border-0 w-12 h-18 rounded-full flex items-center justify-center hover:bg-red-50">
                                        <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg" transform="matrix(1, 0, 0, 1, 0, 0)">
                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round"
                                                stroke-linejoin="round"></g>
                                            <g id="SVGRepo_iconCarrier">
                                                <path fill-rule="evenodd"
                                                    d="M20.4852814,14 L12,22.4852814 L3.51471863,14 L12,5.51471863 L20.4852814,14 Z M6.34314575,14 L12,19.6568542 L17.6568542,14 L12,8.34314575 L6.34314575,14 Z M4.15672797,4.28593933 C6.18580699,2.22227176 8.98673097,1 12,1 C15.9721115,1 19.5752376,3.12390371 21.5277839,6.49927404 L19.7965722,7.50072596 C18.1979627,4.73720691 15.2508538,3 12,3 C9.38603956,3 6.96846584,4.12319378 5.29042786,6 L8,6 L8,8 L2.40310647,8 L2.00227469,2.0674107 L3.99772531,1.9325893 L4.15672797,4.28593933 Z">
                                                </path>
                                            </g>
                                        </svg>

                                    </div>
                                </button>
                                <button @click="CropperPopupSet(index)" title="Rotate Right"
                                    class="inline-flex items-center top-1 right-1 my-auto">
                                    <div
                                        class="my-auto mx-2 bg-[#F5F7F9] border-0 w-12 h-18 rounded-full flex items-center justify-center hover:bg-red-50">
                                        <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round"
                                                stroke-linejoin="round"></g>
                                            <g id="SVGRepo_iconCarrier">
                                                <path fill-rule="evenodd"
                                                    d="M3.04477723,14 L11.5300586,5.51471863 L20.01534,14 L11.5300586,22.4852814 L3.04477723,14 Z M17.1869129,14 L11.5300586,8.34314575 L5.87320435,14 L11.5300586,19.6568542 L17.1869129,14 Z M19.3733306,4.28593933 L19.5323333,1.9325893 L21.5277839,2.0674107 L21.1269521,8 L15.5300586,8 L15.5300586,6 L18.2396307,6 C16.5615928,4.12319378 14.144019,3 11.5300586,3 C8.27920479,3 5.33209587,4.73720691 3.73348642,7.50072596 L2.00227469,6.49927404 C3.954821,3.12390371 7.5579471,1 11.5300586,1 C14.5433276,1 17.3442516,2.22227176 19.3733306,4.28593933 Z">
                                                </path>
                                            </g>
                                        </svg>
                                    </div>
                                </button>
                                <button @click="CropperPopupSet(index)" title="Cropper"
                                    class="inline-flex items-center top-1 right-1 my-auto">
                                    <div
                                        class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="22px"
                                            height="22px" viewBox="0 0 1920 1920">
                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                d="M426.667 0h106.666v1386.67H1920v106.66H426.667V0zM320 426.667H0v106.666h320V426.667zm320 0v106.666h746.67V1280h106.66V426.667H640zM1493.33 1600h-106.66v320h106.66v-320z" />
                                        </svg>
                                    </div>
                                </button>

                                <button @click="remove(files.indexOf(file))" title="Remove file"
                                    class="inline-flex items-center top-1 right-1 my-auto">
                                    <div
                                        class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50">
                                        <svg xmlns="http://www.w3.org/2000/svg"
                                            xmlns:xlink="http://www.w3.org/1999/xlink" fill="red" height="13px"
                                            width="13px" version="1.1" id="Layer_1" viewBox="0 0 492 492"
                                            xml:space="preserve">
                                            <g>
                                                <g>
                                                    <path
                                                        d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z" />
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Preview Sidebar -->
                <div class="w-full lg:w-1/4 p-4 rounded-lg">
                    <!-- Orientation Selection -->
                    <div class="flex justify-around mb-5">
                        <div class="inline-flex gap-8">
                            <h3 class="text-lg font-semibold text-gray-800 mt-12">Orientations</h3>
                            <!-- Vertical Option -->
                            <button @click="downloadOrientation = 'vertical'" :class="[
                                ' w-20 p-3 h-24 rounded-lg border-2 transition-all duration-200 hover:shadow-lg',
                                downloadOrientation === 'vertical'
                                    ? 'border-red-500 bg-red-50'
                                    : 'border-gray-200 hover:border-red-300'
                            ]">
                                <div class="aspect-square w-full flex gap-1">
                                    <div class="w-1/2 bg-red-500 rounded"></div>
                                    <div class="w-1/2 bg-red-500 rounded"></div>
                                </div>
                                <p class="mt-2 text-xs font-medium text-center"
                                    :class="downloadOrientation === 'vertical' ? 'text-red-600' : 'text-gray-600'">
                                    Vertical
                                </p>
                            </button>
                            <!-- Horizontal Option -->
                            <button @click="downloadOrientation = 'horizontal'" :class="[
                                'w-20 p-3 h-24 rounded-lg border-2 transition-all duration-200 hover:shadow-lg',
                                downloadOrientation === 'horizontal'
                                    ? 'border-red-500 bg-red-50'
                                    : 'border-gray-200 hover:border-red-300'
                            ]">
                                <div class="aspect-square w-full flex flex-col gap-1">
                                    <div class="h-1/2 bg-red-500 rounded"></div>
                                    <div class="h-1/2 bg-red-500 rounded"></div>
                                </div>
                                <p class="mt-2 text-xs font-medium text-center"
                                    :class="downloadOrientation === 'horizontal' ? 'text-red-600' : 'text-gray-600'">
                                    Horizontal
                                </p>
                            </button>
                        </div>
                    </div>


                    <!-- Preview Section -->
                    <div class=" space-y-10">
                        <div v-if="downloadOrientation === 'vertical'"
                            class="overflow-auto border border-gray-300 rounded-md"
                            style="max-height: 450px; width: 390px;">
                            <canvas ref="verticalCanvas" class="w-full"></canvas>
                        </div>
                        <div v-if="downloadOrientation === 'horizontal'"
                            class="overflow-auto border border-gray-300 rounded-md"
                            style="max-height: 450px; width: 390px;">
                            <canvas ref="horizontalCanvas" class="h-full"></canvas>
                        </div>

                    </div>
                </div>

            </div>
        </div>



        <div v-if="!this.submitted && files.length === 0">
            <div class="inline-flex items-center justify-center w-full px-4">
                <hr class="w-full h-px my-5 bg-gray-200 border-0 dark:bg-gray-700" />
                <span
                    class="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">or</span>
            </div>

            <div class="px-4">
                <p class="text-base md:text-xl font-semibold text-black mb-2">
                    Import from URL
                </p>
                <form>
                    <label for="search"
                        class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                    <div class="relative">
                        <input type="text" v-model="fileUrl" id="upload"
                            class="block w-full pe-24 truncate p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-red-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-red-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            placeholder="Add file URL" />
                        <button type="submit" @click="handleUpload" v-if="fileUrl"
                            class="text-black absolute end-2.5 bottom-2.5 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                            Upload
                        </button>
                    </div>
                </form>
            </div>
        </div>

        <div class="flex flex-wrap shadow-lg mt-4 rounded-lg p-4" v-if="this.files.length && this.submitted">
            <div class="w-full flex justify-between">
                <h3 class="my-auto ml-5">
                    Results({{ this.files.length }})
                </h3>
                <div>
                    <button @click="removeAllFiles" type="button"
                        class="py-2.5 px-3 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-red-600 focus:ring-4 focus:ring-gray-100">
                        Cancel All
                    </button>
                </div>
            </div>
            <div class="flex flex-wrap justify-between w-full p-3 my-1" v-for="(file, index) in files" :key="index">
                <div
                    class="flex flex-wrap flex-row w-full justify-between gap-2 border-2 border-gray-200 rounded-md p-2">
                    <div class="flex flex-wrap gap-2">
                        <img :src="file.dataURL" class="rounded-lg h-5 my-1 mx-auto object-contain" style="
                                object-fit: cover;
                                width: 60px;
                                height: 55px;
                            " />
                        <div class="flex flex-col my-auto">
                            <span class="text-xs font-semibold text-red-600 my-1">{{
                                file.name.substr(file.name.length - 10)
                                }}</span>
                            <span class="text-red-600">{{
                                Math.floor(file.file.size / 1024) + "KB"
                            }}</span>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="my-auto">
                            <a v-if="file.uploaded" target="_blank" :href="file.dataURL" :download="file.name"
                                class="w-full bg-red-800 p-1 mt-1 text-white rounded">Download</a>
                        </div>
                        <div
                            class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center">
                            <button @click="remove(files.indexOf(file))" title="Remove file"
                                class="inline-flex items-center top-2 right-1">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    fill="red" height="13px" width="13px" version="1.1" id="Layer_1"
                                    viewBox="0 0 492 492" xml:space="preserve">
                                    <g>
                                        <g>
                                            <path
                                                d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z" />
                                        </g>
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- cropper Pop up start.. -->
        <div v-if="cropperPopup" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 flex-col">
            <div class="w-[720px] relative">
                <button class="inline-flex items-center my-auto absolute top-5 right-5 z-50 px-2 py-2 rounded bg-white"
                    @click="cropperPopup = false">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="red"
                        height="13px" width="13px" version="1.1" id="Layer_1" viewBox="0 0 492 492"
                        xml:space="preserve">
                        <g>
                            <g>
                                <path
                                    d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z" />
                            </g>
                        </g>
                    </svg>
                </button>
                <div class="bg-white p-4 rounded-lg w-[720px] relative">
                    <VuePictureCropper :aspectRatio="1 / 1" :boxStyle="{
                        width: '100%',
                        height: '500px',
                        backgroundColor: '#f8f8f8',
                        margin: 'auto',
                    }" :img="pic" :options="options" @cropend="getResult" ref="cropperRef" />

                    <div class="flex flex-wrap justify-center flex-col">
                        <div class="inline-flex rounded-md shadow-sm my-2" role="group">
                            <button @click="rotateTo(-45)" type="button"
                                class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-l-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 gap-2">
                                <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg" transform="matrix(1, 0, 0, 1, 0, 0)">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path fill-rule="evenodd"
                                            d="M20.4852814,14 L12,22.4852814 L3.51471863,14 L12,5.51471863 L20.4852814,14 Z M6.34314575,14 L12,19.6568542 L17.6568542,14 L12,8.34314575 L6.34314575,14 Z M4.15672797,4.28593933 C6.18580699,2.22227176 8.98673097,1 12,1 C15.9721115,1 19.5752376,3.12390371 21.5277839,6.49927404 L19.7965722,7.50072596 C18.1979627,4.73720691 15.2508538,3 12,3 C9.38603956,3 6.96846584,4.12319378 5.29042786,6 L8,6 L8,8 L2.40310647,8 L2.00227469,2.0674107 L3.99772531,1.9325893 L4.15672797,4.28593933 Z">
                                        </path>
                                    </g>
                                </svg>
                                Rotate Left
                            </button>
                            <button @click="reset" type="button"
                                class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border-t border-b border-gray-900 hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 gap-2">
                                <svg class="w-3 h-3" fill="currentColor" viewBox="0 0 1920 1920"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path
                                            d="M960 0v213.333c411.627 0 746.667 334.934 746.667 746.667S1371.627 1706.667 960 1706.667 213.333 1371.733 213.333 960c0-197.013 78.4-382.507 213.334-520.747v254.08H640V106.667H53.333V320h191.04C88.64 494.08 0 720.96 0 960c0 529.28 430.613 960 960 960s960-430.72 960-960S1489.387 0 960 0"
                                            fill-rule="evenodd"></path>
                                    </g>
                                </svg>
                                Reset
                            </button>
                            <button @click="rotateTo(45)" type="button"
                                class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-r-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 gap-2">
                                <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path fill-rule="evenodd"
                                            d="M3.04477723,14 L11.5300586,5.51471863 L20.01534,14 L11.5300586,22.4852814 L3.04477723,14 Z M17.1869129,14 L11.5300586,8.34314575 L5.87320435,14 L11.5300586,19.6568542 L17.1869129,14 Z M19.3733306,4.28593933 L19.5323333,1.9325893 L21.5277839,2.0674107 L21.1269521,8 L15.5300586,8 L15.5300586,6 L18.2396307,6 C16.5615928,4.12319378 14.144019,3 11.5300586,3 C8.27920479,3 5.33209587,4.73720691 3.73348642,7.50072596 L2.00227469,6.49927404 C3.954821,3.12390371 7.5579471,1 11.5300586,1 C14.5433276,1 17.3442516,2.22227176 19.3733306,4.28593933 Z">
                                        </path>
                                    </g>
                                </svg>
                                Rotate Right
                            </button>
                        </div>
                        <div class="inline-flex rounded-md shadow-sm my-2" role="group">
                            <button @click="flipY" type="button"
                                class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-l-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
                                <svg class="w-3 h-3" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path
                                            d="m 13 5 c 0 -0.265625 -0.105469 -0.519531 -0.292969 -0.707031 l -4 -4 c -0.390625 -0.3906252 -1.023437 -0.3906252 -1.414062 0 l -4 4 c -0.390625 0.390625 -0.390625 1.023437 0 1.414062 s 1.023437 0.390625 1.414062 0 l 2.292969 -2.292969 v 9.171876 l -2.292969 -2.292969 c -0.390625 -0.390625 -1.023437 -0.390625 -1.414062 0 s -0.390625 1.023437 0 1.414062 l 4 4 c 0.390625 0.390625 1.023437 0.390625 1.414062 0 l 4 -4 c 0.1875 -0.1875 0.292969 -0.441406 0.292969 -0.707031 s -0.105469 -0.519531 -0.292969 -0.707031 c -0.390625 -0.390625 -1.023437 -0.390625 -1.414062 0 l -2.292969 2.292969 v -9.171876 l 2.292969 2.292969 c 0.390625 0.390625 1.023437 0.390625 1.414062 0 c 0.1875 -0.1875 0.292969 -0.441406 0.292969 -0.707031 z m 0 0"
                                            fill="currentColor"></path>
                                    </g>
                                </svg>
                                Flip Vertical
                            </button>
                            <button @click="flipX" type="button"
                                class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-l-0 border-gray-900 rounded-r-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 gap-2">
                                <svg class="w-3 h-3" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg"
                                    fill="currentColor">
                                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                    <g id="SVGRepo_iconCarrier">
                                        <path
                                            d="m 5 3 c -0.265625 0 -0.519531 0.105469 -0.707031 0.292969 l -4 4 c -0.3906252 0.390625 -0.3906252 1.023437 0 1.414062 l 4 4 c 0.390625 0.390625 1.023437 0.390625 1.414062 0 s 0.390625 -1.023437 0 -1.414062 l -2.292969 -2.292969 h 9.171876 l -2.292969 2.292969 c -0.390625 0.390625 -0.390625 1.023437 0 1.414062 s 1.023437 0.390625 1.414062 0 l 4 -4 c 0.390625 -0.390625 0.390625 -1.023437 0 -1.414062 l -4 -4 c -0.1875 -0.1875 -0.441406 -0.292969 -0.707031 -0.292969 s -0.519531 0.105469 -0.707031 0.292969 c -0.390625 0.390625 -0.390625 1.023437 0 1.414062 l 2.292969 2.292969 h -9.171876 l 2.292969 -2.292969 c 0.390625 -0.390625 0.390625 -1.023437 0 -1.414062 c -0.1875 -0.1875 -0.441406 -0.292969 -0.707031 -0.292969 z m 0 0"
                                            fill="currentColor"></path>
                                    </g>
                                </svg>
                                Flip Horizontal
                            </button>
                        </div>
                    </div>
                    <div class="w-[90%] mx-auto">
                        <button @click="cropperPopup = false"
                            class="w-[46%] mt-4 px-4 mx-2 py-1.5 text-black border-2 rounded-lg cursor-pointer hover:bg-red-50">
                            Cancel
                        </button>
                        <button @click="confirmCrop"
                            class="w-[46%] mt-4 px-4 py-1.5 bg-red-800 text-white rounded-lg cursor-pointer hover:bg-red-600">
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- cropper Pop up end.. -->
    </div>
</template>


<script>
import { ref, watch, onMounted } from 'vue';
import axios from "axios";
import VuePictureCropper, { cropper } from "vue-picture-cropper";
import { PDFDocument, rgb } from 'pdf-lib';
import "cropperjs/dist/cropper.css";
export default {
    props: {
        translations: Object,
        files: Array,
    },
    components: {
        VuePictureCropper,
    },
    data() {
        return {
            options: {
                viewMode: 2,
                dragMode: "crop",
                aspectRatio: NaN,
            },
            cropperPopup: false,
            selectedFormat: "jpg", // Default format is JPG
            pic: "",
            result: null,
            files: [],
            currentFileIndex: null,
            convertedPdfUrl: null,
            showTemplateModal: false,
        };
    },
    methods: {
        rotateTo(e) {
            cropper.rotate(e);
            // Reset last selection and results
        },

        flipX() {
            let flipp = this.flipXValue === true ? 1 : -1;
            cropper.scaleX(flipp);
            this.flipXValue = !this.flipXValue;
        },
        flipY() {
            let flipp = this.flipYValue === true ? 1 : -1;
            cropper.scaleY(flipp);
            this.flipYValue = !this.flipYValue;
        },

        getResult(result) {
            this.result = result;
        },

        reset() {
            if (!cropper) return;
            cropper.reset();
        },
        async mergeAllImages() {
            if (this.files.length === 0) return;

            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            let totalWidth = 0;
            let totalHeight = 0;
            let maxWidth = 0;

            // Load images asynchronously
            const images = await Promise.all(
                this.files.map(async (file) => {
                    const img = new Image();
                    img.src = file.dataURL;

                    // Wait for image to load
                    await new Promise((resolve) => {
                        img.onload = () => resolve();
                    });

                    totalWidth += img.width;
                    totalHeight += img.height;
                    maxWidth = Math.max(maxWidth, img.width);

                    return img;
                })
            );

            // Set canvas size based on selected orientation
            if (this.downloadOrientation === "vertical") {
                canvas.width = maxWidth;
                canvas.height = totalHeight;
            } else if (this.downloadOrientation === "horizontal") {
                canvas.width = totalWidth;
                canvas.height = Math.max(...images.map((img) => img.height));
            }

            // Draw images onto the canvas
            let currentX = 0;
            let currentY = 0;

            images.forEach((img) => {
                if (this.downloadOrientation === "vertical") {
                    ctx.drawImage(img, 0, currentY);
                    currentY += img.height;
                } else if (this.downloadOrientation === "horizontal") {
                    ctx.drawImage(img, currentX, 0);
                    currentX += img.width;
                }
            });

            // Handle format export
            if (this.selectedFormat === "jpg" || this.selectedFormat === "png" || this.selectedFormat === "gif") {
                this.downloadImage(canvas);
            } else if (this.selectedFormat === "pdf") {
                this.downloadPDF(canvas);
            }
        },

        downloadImage(canvas) {
            // Convert canvas to image (JPG/PNG)
            canvas.toBlob((blob) => {
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = `merged.${this.selectedFormat}`;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url); // Clean up
            }, `image/${this.selectedFormat}`, 0.95);
        },

        downloadPDF(canvas) {
            (async () => {
                // Create a new PDF document
                const pdfDoc = await PDFDocument.create();

                // Convert the canvas to a PNG image as bytes
                const imageBytes = await fetch(canvas.toDataURL()).then((res) => res.arrayBuffer());

                // Embed the image into the PDF document
                const pdfImage = await pdfDoc.embedPng(imageBytes);

                // Get the dimensions of the image
                const { width, height } = pdfImage;

                // Add a page to the document with the same dimensions as the image
                const page = pdfDoc.addPage([width, height]);

                // Draw the image onto the PDF page
                page.drawImage(pdfImage, {
                    x: 0,
                    y: 0,
                    width,
                    height,
                });

                // Serialize the PDF to bytes
                const pdfBytes = await pdfDoc.save();

                // Create a Blob and trigger download
                const blob = new Blob([pdfBytes], { type: "application/pdf" });
                const url = URL.createObjectURL(blob);
                const a = document.createElement("a");
                a.href = url;
                a.download = "merged.pdf";
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                URL.revokeObjectURL(url); // Clean up the URL object
            })();
        },
    },
    setup() {
        const files = ref([]);
        const dragActive = ref(false);
        const submitted = ref(false);
        const settingPopup = ref(false);
        const cropperPopup = ref(false);
        const conversionCompleted = ref(false);
        const disableConvertButton = ref(true);
        const fileUrl = ref("");
        const currentFileIndex = ref(null);
        const pic = ref("");
        const downloadOrientation = ref("vertical"); // Default download orientation
        const verticalCanvas = ref(null); // Reference to the vertical canvas
        const horizontalCanvas = ref(null); // Reference to the horizontal canvas

        const handleFileSelection = (event) => {
            const newFiles = Array.from(event.target.files).map((file) => ({
                dataURL: URL.createObjectURL(file),
                name: file.name,
                uploading: false,
                uploaded: false,
                uploadProgress: 0,
                error: null,
                file: file, // Store the actual File object
            }));

            files.value.push(...newFiles);
            disableConvertButton.value = false;
        };

        const removeAllFiles = () => {
            files.value = []; // Empty the files array
            submitted.value = false;
            conversionCompleted.value = false; // Reset conversion completed flag
        };

        const uploadFiles = async () => {
            submitted.value = true;
            for (let i = 0; i < files.value.length; i++) {
                if (files.value[i].uploaded) {
                    continue;
                }
                await uploadFile(files.value[i]);
                conversionCompleted.value = true;
            }
        };

        const download = async (index) => {
            alert("download");
        };

        const remove = async (index) => {
            files.value.splice(index, 1);
            if (!files.value.length) {
                submitted.value = false;
            }
        };

        // Function to merge all JPGs based on orientation
        // const mergeAllJPGs = async () => {
        //     if (files.value.length === 0) return;

        //     const canvas = document.createElement("canvas");
        //     const ctx = canvas.getContext("2d");

        //     let totalWidth = 0;
        //     let totalHeight = 0;
        //     let maxWidth = 0;

        //     // Load all images
        //     const images = await Promise.all(
        //         files.value.map(async (file) => {
        //             const img = new Image();
        //             img.src = file.dataURL;

        //             // Wait for image to load
        //             await new Promise((resolve) => {
        //                 img.onload = () => resolve();
        //             });

        //             // Calculate dimensions
        //             totalWidth += img.width;
        //             totalHeight += img.height;
        //             maxWidth = Math.max(maxWidth, img.width);

        //             return img;
        //         })
        //     );

        //     // Set canvas dimensions based on the selected orientation
        //     if (downloadOrientation.value === "vertical") {
        //         // Vertically stack images
        //         canvas.width = maxWidth;
        //         canvas.height = totalHeight;
        //     } else if (downloadOrientation.value === "horizontal") {
        //         // Horizontally stack images
        //         canvas.width = totalWidth;
        //         canvas.height = Math.max(...images.map((img) => img.height));
        //     }

        //     // Draw images onto the canvas
        //     let currentX = 0;
        //     let currentY = 0;

        //     images.forEach((img) => {
        //         if (downloadOrientation.value === "vertical") {
        //             // Stack images vertically
        //             ctx.drawImage(img, 0, currentY);
        //             currentY += img.height;
        //         } else if (downloadOrientation.value === "horizontal") {
        //             // Stack images horizontally
        //             ctx.drawImage(img, currentX, 0);
        //             currentX += img.width;
        //         }
        //     });

        //     // Convert the canvas to a single JPG image
        //     canvas.toBlob(
        //         (blob) => {
        //             const url = URL.createObjectURL(blob);
        //             const a = document.createElement("a");
        //             a.href = url;
        //             a.download = "merged.jpg";
        //             document.body.appendChild(a);
        //             a.click();
        //             document.body.removeChild(a);
        //             URL.revokeObjectURL(url); // Clean up
        //         },
        //         "image/jpeg",
        //         0.95 // Quality factor
        //     );
        // };

        // Method: Update Canvases
        const updateCanvases = async () => {
            if (files.value.length === 0) return; // If no files, skip updates

            const images = await Promise.all(
                files.value.map(async (file) => {
                    const img = new Image();
                    img.src = file.dataURL;
                    await new Promise((resolve) => {
                        img.onload = resolve;
                    });
                    return img;
                })
            );

            // Update Vertical Canvas
            if (downloadOrientation.value === "vertical") {
                const vCtx = verticalCanvas.value.getContext("2d");
                let vTotalHeight = 0;
                let vMaxWidth = 0;
                images.forEach((img) => {
                    vTotalHeight += img.height;
                    vMaxWidth = Math.max(vMaxWidth, img.width);
                });
                verticalCanvas.value.width = vMaxWidth;
                verticalCanvas.value.height = vTotalHeight;
                let vY = 0;
                images.forEach((img) => {
                    vCtx.drawImage(img, 0, vY);
                    vY += img.height;
                });
            }
            // Update Horizontal Canvas
            if (downloadOrientation.value === "horizontal") {
                const hCtx = horizontalCanvas.value.getContext("2d");
                let hTotalWidth = 0;
                let hMaxHeight = 0;
                images.forEach((img) => {
                    hTotalWidth += img.width;
                    hMaxHeight = Math.max(hMaxHeight, img.height);
                });

                const scaleFactor = Math.min(2, 420 / hMaxHeight); // Scale up to 2x or to fit height

                horizontalCanvas.value.width = hTotalWidth * scaleFactor;
                horizontalCanvas.value.height = hMaxHeight * scaleFactor;

                let hX = 0;
                images.forEach((img) => {
                    const scaledWidth = img.width * scaleFactor;
                    const scaledHeight = img.height * scaleFactor;
                    const yOffset = (horizontalCanvas.value.height - scaledHeight) / 2;
                    hCtx.drawImage(img, hX, yOffset, scaledWidth, scaledHeight);
                    hX += scaledWidth;
                });
            }

        };

        // Watchers
        watch([files, downloadOrientation], updateCanvases, { deep: true });


        // Lifecycle Hooks
        onMounted(() => {
            // Initialize canvases
            verticalCanvas.value = verticalCanvas.value;
            horizontalCanvas.value = horizontalCanvas.value;
        });

        const uploadFile = async (file) => {
            file.uploading = true;
            file.uploadProgress = 0;

            const objectURL = URL.createObjectURL(file.file);
            const image = new Image();
            image.onload = () => {
                const canvas = document.createElement("canvas");
                canvas.width = image.width;
                canvas.height = image.height;

                const ctx = canvas.getContext("2d");
                ctx.drawImage(image, 0, 0);

                canvas.toBlob((blob) => {
                    const newFileName =
                        file.name.replace(/\.[^/.]+$/, "") + ".png"; // Replace existing extension with .jpg
                    const jpgFile = new File([blob], newFileName, {
                        type: "image/jpeg",
                        lastModified: new Date(),
                    });

                    setTimeout(() => {
                        file.uploadProgress = 100;
                        file.uploading = false;
                        file.uploaded = true;

                        file.dataURL = URL.createObjectURL(jpgFile);
                        file.name = newFileName;
                        URL.revokeObjectURL(objectURL);
                    }, 500);
                }, "image/jpeg");
            };
            image.src = objectURL;

            image.onerror = () => {
                console.error("Error loading image for conversion.");
                file.uploading = false;
                file.error = "Error converting image";
            };
        };

        const confirmCrop = async () => {
            const dataURL = cropper.getDataURL();
            console.log("Cropped Image Data URL:", dataURL);

            // Update the Data URL and the file object in the files array
            const croppedBlob = dataURLtoBlob(dataURL);
            const newFileName =
                files.value[currentFileIndex.value].name.replace(
                    /\.[^/.]+$/,
                    ""
                ) + "_cropped.png";

            files.value[currentFileIndex.value].dataURL = dataURL;
            files.value[currentFileIndex.value].file = new File(
                [croppedBlob],
                newFileName,
                { type: croppedBlob.type }
            );
            files.value[currentFileIndex.value].name = newFileName;

            cropperPopup.value = false;
        };

        const dataURLtoBlob = (dataURL) => {
            const byteString = atob(dataURL.split(",")[1]);
            const mimeString = dataURL
                .split(",")[0]
                .split(":")[1]
                .split(";")[0];

            const arrayBuffer = new ArrayBuffer(byteString.length);
            const intArray = new Uint8Array(arrayBuffer);

            for (let i = 0; i < byteString.length; i++) {
                intArray[i] = byteString.charCodeAt(i);
            }

            return new Blob([intArray], { type: mimeString });
        };

        const CropperPopupSet = (index) => {
            currentFileIndex.value = index;
            cropperPopup.value = true;
            pic.value = files.value[currentFileIndex.value].dataURL;
            console.log(files.value[currentFileIndex.value].dataURL);
        };

        const uploadFileOld = async (file) => {
            const formData = new FormData();
            formData.append("convert", "to-jpg");
            formData.append("images", file.file); // Append the actual File object

            const config = {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round(
                        (progressEvent.loaded / progressEvent.total) * 100
                    );
                    file.uploading = true;
                    file.uploadProgress = progress;
                },
            };

            try {
                const response = await axios.post(
                    "/file-upload-convert",
                    formData,
                    config
                );
                file.uploading = false;
                file.uploaded = true;
                file.uploadProgress = 100;
                file.responseData = response.data;
                file.downloadPath = response.data.path;
            } catch (error) {
                file.uploading = false;
                file.error = "Error uploading image";
                console.error("Error uploading image:", error);
            }
        };

        const dragover = async () => {
            dragActive.value = true;
        };
        const dragleave = async (event) => {
            dragActive.value = false;
        };
        const handleUpload = async (event) => {
            event.preventDefault();
            const isImage = await checkImageURL(fileUrl.value);
            if (!isImage) {
                alert("Invalid format: Only image URLs are allowed.");
                return;
            }

            try {
                const response = await fetch(fileUrl.value);
                if (!response.ok) {
                    console.error(
                        "Error:",
                        response.status,
                        response.statusText
                    );
                    alert("Error fetching image from the URL.");
                    return;
                }

                const blob = await response.blob();
                const file = new File(
                    [blob],
                    fileUrl.value.substring(fileUrl.value.lastIndexOf("/") + 1),
                    { type: blob.type }
                );

                const imageUrl = URL.createObjectURL(blob);
                const fileObject = {
                    dataURL: imageUrl,
                    name: file.name,
                    type: blob.type,
                    uploading: false,
                    uploaded: false,
                    uploadProgress: 0,
                    error: null,
                    file: file,
                };
                files.value.push(fileObject);
                disableConvertButton.value = false;
                fileUrl.value = "";
            } catch (error) {
                console.error("Error handling image upload:", error);
            }
        };

        const checkImageURL = async (url) => {
            console.log("Checking image URL:", url);
            const response = await fetch(url);
            console.log("Fetch response:", response);
            if (!response.ok) {
                console.error("Error:", response.status, response.statusText);
                throw new Error("Failed to fetch image.");
            }

            const contentType = response.headers.get("content-type");
            console.log("Content-Type:", contentType);
            if (
                contentType &&
                (contentType.startsWith("image/") ||
                    contentType.startsWith("img/"))
            ) {
                const imageData = await response.blob();
                console.log("Image Data:", imageData);
                if (imageData && imageData.type.startsWith("image/")) {
                    console.log("Image URL is valid.");
                    return true;
                } else {
                    throw new Error("Response data is not an image.");
                }
            } else {
                throw new Error("Invalid content type for image.");
            }
        };

        const dropFile = (event) => {
            event.preventDefault();
            dragActive.value = false;
            const newFiles = Array.from(event.dataTransfer.files).map(
                (file) => ({
                    dataURL: URL.createObjectURL(file),
                    name: file.name,
                    uploading: false,
                    uploaded: false,
                    uploadProgress: 0,
                    error: null,
                    file: file, // Store the actual File object
                })
            );

            files.value.push(...newFiles);
        };
        return {
            dragover,
            dragleave,
            dropFile,
            remove,
            dragActive,
            submitted,
            files,
            handleFileSelection,
            uploadFiles,
            settingPopup,
            cropperPopup,
            conversionCompleted,
            removeAllFiles,
            disableConvertButton,
            fileUrl,
            handleUpload,
            CropperPopupSet,
            confirmCrop,
            pic,
            // mergeAllJPGs,
            downloadOrientation,
            verticalCanvas,
            horizontalCanvas,
        };

    },
};
</script>
<style scoped>
.flex-grow {
    flex-grow: 1;
}

canvas {
    display: block;
    margin: 0 auto;
    /* Light gray background */
}

.overflow-auto {
    overflow: auto;
    max-height: 600px;
    /* Adjust as needed */
    scrollbar-width: thin;
    /* Add thin horizontal scrollbar */
    scrollbar-color: #ef4444 #f1f1f1;
    /* Change scrollbar color */
    /* Enable scrolling */
}
</style>
