<!-- resources/js/MyComponent.vue -->
<template>
    <div>
        <div class="flex flex-wrap flex-col flex-1 gap-3" v-if="!this.submitted">
                <div class="h-auto min-h-fit rounded-lg p-3 rounded-lg flex-grow">
                    <div  @drop.prevent="dropFile" @dragenter="dropFile" @dragover="dropFile" :class="{ ' bg-pink-800': dragActive }" style="height: 280px" class=" flex items-center justify-center w-full px-12 py-8 border-4 border-gray-300 border-dashed rounded dark:border-2">
                        <div class="flex flex-col items-center justify-center gap-1 w-full text-white dark:text-gray-100 py-2">

                            <label  for="file" class="w-auto flex items-center hover:bg-gray-900 bg-red-500 px-5 py-2 rounded-full shadow-lg border cursor-pointer text-white my-auto font-medium text-[22px] gap-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25px" height="25px" viewBox="0 0 24 24" fill="none" data-v-5d608623=""><g clip-path="url(#clip0_429_10970)" data-v-5d608623=""><circle cx="12" cy="11.999" r="9" stroke="#FFFFFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" data-v-5d608623=""></circle><path d="M12 9V15" stroke="#FFFFFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" data-v-5d608623=""></path><path d="M9 12H15" stroke="#FFFFFF" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round" data-v-5d608623=""></path></g><defs data-v-5d608623=""><clipPath id="clip0_429_10970" data-v-5d608623=""><rect width="24" height="24" fill="white" data-v-5d608623=""></rect></clipPath></defs></svg>
                                <span class="">Upload your JPG Files</span>
                                <input  type="file" multiple name="fields" @change="handleFileSelection"  ref="file" accept=".pdf,.jpg,.jpeg,.png" id="file" class="hidden" />
                            </label>
                            <p class="text-base md:text-xl mt-2.5 text-gray-400 font-semibold text-black">Or drop files here.</p>
                        </div>
                    </div>
                </div>

                <!-- show output -->
                <div class="flex md:w-full" v-if="this.files.length && !this.submitted">
                <div class="flex flex-wrap shadow-2xl mt-4 rounded-lg p-4 w-full">
                    <div class="flex justify-between w-full">
                        <h3 class="w-full">Images ({{this.files.length}})</h3> 
                       <div class="w-full flex justify-end">
                        <button @click="removeAllFiles" type="button" class="py-2.5 px-3 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-red-600 focus:ring-4 focus:ring-gray-100">Cancel All</button>
                       </div>
                    </div>
                    <div class="w-full p-3 my-1 flex flex-wrap flex-row" v-for="(file, index) in files">
                        <div class="w-full flex flex-wrap flex-row justify-between gap-2  border-2 border-gray-200 rounded-md p-2  relative">
                            <div class="w-full flex flex-wrap flex-row justify-between">
                                
                                <div class="flex flex-wrap gap-2">
                                    
                                    <img :src="file.dataURL" class="rounded-lg my-2 h-5 mx-auto object-contain" style="object-fit: cover; width: 60px; height: 55px">
                                    <div class="flex flex-col my-auto">
                                         <span class="text-xs font-semibold text-red-600 my-2">{{ file.name.substr(file.name.length - 10) }}</span>
                                        <span class="text-red-600">{{Math.floor(file.file.size/1024)+"KB"}}</span>
                                    </div>
                                </div>
                                <div class="flex">
                                    <button @click="CropperPopupSet(index)" title="Cropper" class="inline-flex items-center top-1 right-1 my-auto">
                                            <div class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="22px" height="22px" viewBox="0 0 1920 1920">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M426.667 0h106.666v1386.67H1920v106.66H426.667V0zM320 426.667H0v106.666h320V426.667zm320 0v106.666h746.67V1280h106.66V426.667H640zM1493.33 1600h-106.66v320h106.66v-320z"/>
                                            </svg>
                                        </div>
                                        </button>
                                    <button @click="remove(files.indexOf(file))" title="Remove file" class="inline-flex items-center top-1 right-1 my-auto">
                                            <div class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-50">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="red" height="13px" width="13px" version="1.1" id="Layer_1" viewBox="0 0 492 492" xml:space="preserve">
                                            <g>
                                                <g>
                                                    <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </div>
                                        </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="w-full text-end my-2">
                        <button @click="uploadFiles" class="py-2 px-3 me-2 mb-2 text-sm font-medium text-white border rounded-md focus:outline-none bg-red-800  hover:bg-red-400 focus:ring-4 focus:ring-gray-100">
                            Convert
                        </button>
                    </div>
                </div>
            </div>
                 <!-- Url add and convert jpg-to-word -->
            <div v-if="handleFileSelection">
            <div class="inline-flex items-center justify-center w-full px-4">
                <hr class="w-full h-px my-5 bg-gray-200 border-0 dark:bg-gray-700">
                <span class="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">or</span>
            </div>

            <div class="px-4" >
                <p class="text-base md:text-xl font-semibold text-black mb-2">Import from URL</p>
                <form>
                    <label for="search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                    <div class="relative">
                        <input type="text" v-model="fileUrl" id="upload" class="block w-full pe-24  truncate p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-red-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-red-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Add file URL" />
                        <button type="submit" class="text-black absolute end-2.5 bottom-2.5 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800" @click="uploadFiles">Upload</button>
                    </div>
                </form>
            </div>
        </div>
<!-- url end convert jpg-to-word -->
            </div>

           

        <div>
            <div class="flex flex-wrap shadow-2xl rounded-lg" v-if="this.files.length && this.submitted">
                <div class="w-full flex justify-between">
                    <h3>Results({{this.files.length}})</h3>
                    <button class="text-xs bg-transparent hover:bg-white-400 text-red-500 border-2 border-red-500 font-bold py-1 px-1 rounded inline-flex items-center transition-all duration-300" v-if="files.length > 1" @click="downloadAllDocs">Download All</button>
                </div>
                <div class="p-3 my-1 flex flex-wrap flex-col w-full" v-for="(file, index) in files" :key="index">
                    <div class="border-2 border-gray-200 rounded-md p-2 flex flex-wrap flex-row gap-2" >
                        <div class="w-auto text-center">
                            <img :src="file.dataURL" class="rounded-lg h-20 mx-auto object-contain" style="aspect-ratio: 16/9;object-fit: cover;">
                            <span class="text-xs">
                                 <span v-if="file.name.length<10">{{ file.name }}</span>
                                 <span v-else>{{ ".."+file.name.substr(file.name.length - 10)}}</span> - {{ file.reslut }}</span>
                        </div>
                        <div class=" relative flex-grow">
                            <div class="w-full flex flex-wrap" v-if="file.text">
                                <div class="md:w-1/2 mx-auto justify-center">
                                    Translate into:
                                    <div class="dropdown inline-block relative py-1 group">
                                        <button class="text-xs bg-transparent hover:bg-white-400 text-red-500 border-2 border-red-500 font-bold py-1 px-1 rounded inline-flex items-center transition-all duration-300" >
                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-2 mx-1 w-2 md:h-2 md:w-2" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M4 5h7"></path><path d="M9 3v2c0 4.418 -2.239 8 -5 8"></path><path d="M5 9c0 2.144 2.952 3.908 6.7 4"></path><path d="M12 20l4 -9l4 9"></path><path d="M19.1 18h-6.2"></path></svg>
                                            <span>Select Language</span>
                                        </button>
                                        <ul class="dropdown-menu hidden group-hover:block group w-full absolute text-left text-gray-700 pt-1 w-full text-sm bg-white border border-gray-300 rounded shadow-lg" style="max-height: 200px; overflow-y: auto;">
                                            <li><a @click="translate(files.indexOf(file),'en', 'English')" class="cursor-pointer block mx-auto p-1 hover:bg-blue-500 hover:text-white">English</a></li>
                                            <li><a @click="translate(files.indexOf(file),'es', 'Spanish')" class="cursor-pointer block mx-auto p-1 hover:bg-blue-500 hover:text-white">Spanish</a></li>
                                            <li><a @click="translate(files.indexOf(file),'ar', 'Arabic')" class="cursor-pointer block mx-auto p-1 hover:bg-blue-500 hover:text-white">Arabic</a></li>
                                            <li><a @click="translate(files.indexOf(file),'hi', 'Hindi')" class="cursor-pointer block mx-auto p-1 hover:bg-blue-500 hover:text-white">Hindi</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="md:w-1/2 flex flex-row justify-end my-1">
                                    <button v-if="file.text" @click="downloadDoc(file.text)" class="cursor-pointer mx-2 inline-flex gap-1 items-center py-[5px] px-[8px] text-xs rounded hover:bg-gray-400 bg-gray-300 justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 400 400" fill="#000000"><g stroke-width="0"/><g stroke-linecap="round" stroke-linejoin="round"/><g><g><path d="M325,105H250a5,5,0,0,1-5-5V25a5,5,0,1,1,10,0V95h70a5,5,0,0,1,0,10Z"/> <path d="M325,154.83a5,5,0,0,1-5-5V102.07L247.93,30H100A20,20,0,0,0,80,50v98.17a5,5,0,0,1-10,0V50a30,30,0,0,1,30-30H250a5,5,0,0,1,3.54,1.46l75,75A5,5,0,0,1,330,100v49.83A5,5,0,0,1,325,154.83Z"/> <path d="M300,380H100a30,30,0,0,1-30-30V275a5,5,0,0,1,10,0v75a20,20,0,0,0,20,20H300a20,20,0,0,0,20-20V275a5,5,0,0,1,10,0v75A30,30,0,0,1,300,380Z"/> <path d="M275,280H125a5,5,0,0,1,0-10H275a5,5,0,0,1,0,10Z"/> <path d="M200,330H125a5,5,0,0,1,0-10h75a5,5,0,1,1,0,10Z"/> <path d="M325,280H75a30,30,0,0,1-30-30V173.17a30,30,0,0,1,30.19-30l250,1.66a30.09,30.09,0,0,1,29.81,30V250A30,30,0,0,1,325,280ZM75,153.17a20,20,0,0,0-20,20V250a20,20,0,0,0,20,20H325a20,20,0,0,0,20-20V174.83a20.06,20.06,0,0,0-19.88-20l-250-1.66Z"/> <path d="M163.16,236H152.85V190.92H138.67v-8.24h38.67v8.24H163.16Z"/> <path d="M222.23,236H211l-11.8-21-12.5,21h-8.95l16.88-27.77-14.49-25.55h11.17l9.84,17.73,10.43-17.73h9L205.74,207Z"/> <path d="M247.15,236H236.84V190.92H222.66v-8.24h38.67v8.24H247.15Z"/></g></g></svg> Download .doc file
                                    </button>
                                    <button v-if="file.text" @click="copyText(file.text)" title="Copy Text" class="copy-text gap-2 text-xs inline-flex items-center p-[5px] rounded hover:bg-gray-400 bg-gray-300 justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="w-4 h-4"><g transform="translate(-2.25 -2.25)"><path d="M5.173,16.787A2.926,2.926,0,0,1,2.25,13.864V5.173A2.926,2.926,0,0,1,5.173,2.25h8.691a2.926,2.926,0,0,1,2.923,2.923v.543a.75.75,0,1,1-1.5,0V5.173A1.424,1.424,0,0,0,13.864,3.75H5.173A1.424,1.424,0,0,0,3.75,5.173v8.691a1.424,1.424,0,0,0,1.423,1.423.75.75,0,0,1,0,1.5Z" transform="translate(0 0)"></path> <path data-v-5e38c1d8="" id="Rectangle_6894" data-name="Rectangle 6894" d="M2-.75h9.037A2.753,2.753,0,0,1,13.787,2v9.037a2.753,2.753,0,0,1-2.75,2.75H2a2.753,2.753,0,0,1-2.75-2.75V2A2.753,2.753,0,0,1,2-.75Zm9.037,13.037a1.251,1.251,0,0,0,1.25-1.25V2A1.251,1.251,0,0,0,11.037.75H2A1.251,1.251,0,0,0,.75,2v9.037A1.251,1.251,0,0,0,2,12.287Z" transform="translate(8.463 8.463)"></path></g></svg>
                                        Copy To Clipboard
                                    </button>
                                </div>
                            </div>
                            <textarea v-if="file.uploaded && file.text" rows="4" class="w-full border-2 border-gray-200 rounded-md min-h-fit" v-html="file.text"></textarea>
                            <div v-else-if="file.uploaded && (file.text === null || file.text === '')" class="w-full min-h-fit">
                                <div class="flex flex-wrap flex-row w-full justify-between items-center px-4">
                                    <div class="flex flex-wrap justify-between flex-row items-center gap-2">
                                        <img :src="'/images/limit-img.webp'" class="w-12 mx-auto">
                                        <div class="text-start">
                                            <p class="text-md w-full font-bold">No Text Found</p>
                                            <p class="text-sm w-full">You can try again if you think something is wrong.</p>
                                        </div>
                                    </div>
                                    <div class="flex flex-wrap flex-col gap-4 justify-center">
                                        <button  @click="retryUpload(file)" class="cursor-pointer bg-[#DB2777] w-24 hover:bg-[#c5236b] flex text-center text-xs text-white px-2 py-1 shadow-lg rounded-lg gap-3">
                                            <svg viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 inline">
                                                <path data-v-226c004c="" d="M16.9998 19.8333C17.7513 19.8333 18.4719 20.1318 19.0033 20.6632C19.5346 21.1946 19.8332 21.9152 19.8332 22.6667C19.8332 23.4181 19.5346 24.1388 19.0033 24.6701C18.4719 25.2015 17.7513 25.5 16.9998 25.5C16.2484 25.5 15.5277 25.2015 14.9964 24.6701C14.465 24.1388 14.1665 23.4181 14.1665 22.6667C14.1665 21.9152 14.465 21.1946 14.9964 20.6632C15.5277 20.1318 16.2484 19.8333 16.9998 19.8333ZM33.2348 12.5517L30.9823 22.3125L21.2498 20.06L26.6332 16.6883C24.6357 13.4583 21.0657 11.3333 16.9998 11.3333C11.404 11.3333 6.75732 15.385 5.83649 20.7258L3.04565 20.23C4.19315 13.5717 10.0015 8.5 16.9998 8.5C22.0715 8.5 26.534 11.1775 29.0415 15.1867L33.2348 12.5517Z" fill="white"></path>
                                            </svg>
                                            Re-Try
                                        </button>
                                    </div>
                                </div>
                                <div class="w-full text-center my-2">
                                    <div class="cf-turnstile max-w-xs mx-auto" data-sitekey="0x4AAAAAAAYPnENkBR7iR2js" data-callback="javascriptCallback"></div>
                                </div>
                            </div>
                            <div v-else class="animate-pulse w-full border-2 border-gray-200 rounded-md min-h-fit">
                                <div class="flex-1 space-y-1 w-4/6 pl-4 pr-8 py-1">
                                    <div class="p-1 bg-pink-800 rounded w-3/4"></div>
                                    <div class="space-y-1">
                                        <div class="p-1 bg-pink-800 rounded"></div>
                                        <div class="p-1 bg-pink-800 rounded w-5/6"></div>
                                        <div class="p-1 bg-pink-800 rounded"></div>
                                        <div class="p-1 bg-pink-800 rounded w-4/6"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full text-xs py-1" v-if="file.transltion">{{file.transltionLang}}</div>
                            <textarea v-if="file.transltion" rows="4" class="w-full border-2 border-gray-200 rounded-md min-h-fit" v-html="file.transltion"></textarea>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- cropper Pop up start.. -->
        <div v-if="cropperPopup" class="cropper-popup fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 flex flex-col">

            <div class="bg-white p-4 rounded-lg md:w-[720px] relative">
                <VuePictureCropper
                    :aspectRatio="1/1"
                    :boxStyle="{
                        width: '100%',
                        height: '500px',
                        backgroundColor: '#f8f8f8',
                        margin: 'auto'
                    }"
                    :img="pic"
                    :options="options"
                    @cropend="getResult"
                    ref="cropperRef"
                />
                <div class="cropper-cross absolute top-5 right-5 bg-[#b51858] hover:bg-[#9f154d] rounded">
                    <button @click="cropperPopup = false" title="Remove file" class="cropperPopup items-center justify-center my-auto mx-auto border-0 w-6 h-6 rounded-full  inline-flex items-center my-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="red" height="10px" width="10px" version="1.1" id="Layer_1" viewBox="0 0 492 492" xml:space="preserve">
                        <g>
                            <g>
                                <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                            </g>
                        </g>
                    </svg>
                    </button>
                </div>

                <div class="flex flex-wrap justify-center flex-col">
                    <div class="inline-flex rounded-md shadow-sm my-2" role="group">
                        <button @click="rotateTo(-45)" type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-l-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 gap-2">
                            <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" transform="matrix(1, 0, 0, 1, 0, 0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" d="M20.4852814,14 L12,22.4852814 L3.51471863,14 L12,5.51471863 L20.4852814,14 Z M6.34314575,14 L12,19.6568542 L17.6568542,14 L12,8.34314575 L6.34314575,14 Z M4.15672797,4.28593933 C6.18580699,2.22227176 8.98673097,1 12,1 C15.9721115,1 19.5752376,3.12390371 21.5277839,6.49927404 L19.7965722,7.50072596 C18.1979627,4.73720691 15.2508538,3 12,3 C9.38603956,3 6.96846584,4.12319378 5.29042786,6 L8,6 L8,8 L2.40310647,8 L2.00227469,2.0674107 L3.99772531,1.9325893 L4.15672797,4.28593933 Z"></path> </g></svg>
                            Rotate Left
                        </button>
                        <button @click="reset" type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border-t border-b border-gray-900 hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 gap-2">
                            <svg class="w-3 h-3"  fill="currentColor" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M960 0v213.333c411.627 0 746.667 334.934 746.667 746.667S1371.627 1706.667 960 1706.667 213.333 1371.733 213.333 960c0-197.013 78.4-382.507 213.334-520.747v254.08H640V106.667H53.333V320h191.04C88.64 494.08 0 720.96 0 960c0 529.28 430.613 960 960 960s960-430.72 960-960S1489.387 0 960 0" fill-rule="evenodd"></path> </g></svg>
                            Reset
                        </button>
                        <button @click="rotateTo(45)"  type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-r-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 gap-2">
                            <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" d="M3.04477723,14 L11.5300586,5.51471863 L20.01534,14 L11.5300586,22.4852814 L3.04477723,14 Z M17.1869129,14 L11.5300586,8.34314575 L5.87320435,14 L11.5300586,19.6568542 L17.1869129,14 Z M19.3733306,4.28593933 L19.5323333,1.9325893 L21.5277839,2.0674107 L21.1269521,8 L15.5300586,8 L15.5300586,6 L18.2396307,6 C16.5615928,4.12319378 14.144019,3 11.5300586,3 C8.27920479,3 5.33209587,4.73720691 3.73348642,7.50072596 L2.00227469,6.49927404 C3.954821,3.12390371 7.5579471,1 11.5300586,1 C14.5433276,1 17.3442516,2.22227176 19.3733306,4.28593933 Z"></path> </g></svg>
                            Rotate Right
                        </button>
                    </div>
                    <div class="inline-flex rounded-md shadow-sm my-2" role="group">
                        <button @click="flipY" type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-l-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
                            <svg class="w-3 h-3" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="m 13 5 c 0 -0.265625 -0.105469 -0.519531 -0.292969 -0.707031 l -4 -4 c -0.390625 -0.3906252 -1.023437 -0.3906252 -1.414062 0 l -4 4 c -0.390625 0.390625 -0.390625 1.023437 0 1.414062 s 1.023437 0.390625 1.414062 0 l 2.292969 -2.292969 v 9.171876 l -2.292969 -2.292969 c -0.390625 -0.390625 -1.023437 -0.390625 -1.414062 0 s -0.390625 1.023437 0 1.414062 l 4 4 c 0.390625 0.390625 1.023437 0.390625 1.414062 0 l 4 -4 c 0.1875 -0.1875 0.292969 -0.441406 0.292969 -0.707031 s -0.105469 -0.519531 -0.292969 -0.707031 c -0.390625 -0.390625 -1.023437 -0.390625 -1.414062 0 l -2.292969 2.292969 v -9.171876 l 2.292969 2.292969 c 0.390625 0.390625 1.023437 0.390625 1.414062 0 c 0.1875 -0.1875 0.292969 -0.441406 0.292969 -0.707031 z m 0 0" fill="currentColor"></path> </g></svg>
                            Flip Vertical
                        </button>
                        <button @click="flipX"  type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-l-0 border-gray-900 rounded-r-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 gap-2">
                            <svg class="w-3 h-3" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="m 5 3 c -0.265625 0 -0.519531 0.105469 -0.707031 0.292969 l -4 4 c -0.3906252 0.390625 -0.3906252 1.023437 0 1.414062 l 4 4 c 0.390625 0.390625 1.023437 0.390625 1.414062 0 s 0.390625 -1.023437 0 -1.414062 l -2.292969 -2.292969 h 9.171876 l -2.292969 2.292969 c -0.390625 0.390625 -0.390625 1.023437 0 1.414062 s 1.023437 0.390625 1.414062 0 l 4 -4 c 0.390625 -0.390625 0.390625 -1.023437 0 -1.414062 l -4 -4 c -0.1875 -0.1875 -0.441406 -0.292969 -0.707031 -0.292969 s -0.519531 0.105469 -0.707031 0.292969 c -0.390625 0.390625 -0.390625 1.023437 0 1.414062 l 2.292969 2.292969 h -9.171876 l 2.292969 -2.292969 c 0.390625 -0.390625 0.390625 -1.023437 0 -1.414062 c -0.1875 -0.1875 -0.441406 -0.292969 -0.707031 -0.292969 z m 0 0" fill="currentColor"></path> </g></svg>
                            Flip Horizontal
                        </button>
                    </div>

                </div>
                <div class="w-[90%] mx-auto">
                    <button @click="cropperPopup = false" class="w-[46%] mt-4 px-4 mx-2 py-1.5 text-black border-2 rounded-lg cursor-pointer hover:bg-red-50">Cancel</button>
                    <button @click="confirmCrop" class="w-[46%] mt-4 px-4 py-1.5 bg-red-800 text-white rounded-lg cursor-pointer hover:bg-red-600">Confirm</button>
                </div>
            </div>
        </div>
        <!-- cropper Pop up end.. -->
        <ErrorPopupComponent :errorMessage="serverErrorMessage" :errorHeader="yourErrorHeaderVariable"  ref="errorPopup" />
    </div>
</template>

<script>
import { ref } from "vue";
import axios from "axios";
import VuePictureCropper, { cropper }  from 'vue-picture-cropper';
import ErrorPopupComponent from '@/Components/ErrorPopupComponent.vue';
import 'cropperjs/dist/cropper.css';

export default {
    props: {
        translations: Object,
        files: Array
    },
    components: {
        VuePictureCropper,
        ErrorPopupComponent
    },
    data() {
        return {
            options: {
                viewMode: 2,
                dragMode: 'crop',
                aspectRatio: NaN,
            },
            serverErrorMessage: "",
            yourErrorHeaderVariable: "",
            cropperPopup: false,
            pic: '',
            result: null,
            files: [],
            currentFileIndex: null,
            convertedPdfUrl: null,
            quality: 1,
            width: '',
            height: '',
        };
    },
    methods: {
        handleFileSelection(event) {
            const newFiles = Array.from(event.target.files).map((file) => ({
                dataURL: URL.createObjectURL(file),
                name: file.name,
                type: file.type,
                uploading: false,
                uploaded: false,
                uploadProgress: 0,
                error: null,
                file: file, // Store the actual File object
                pdfURL: null,
                convertedPdfUrl: null,
            }));

            for (const file of newFiles) {
                this.uploadFile(file, this.quality); // Convert each file to PDF
            }

            this.files.push(...newFiles);
        },

        rotateTo(e) {
            cropper.rotate(e)
            // Reset last selection and results
        },

        flipX() {
            let flipp = this.flipXValue === true?1:-1;
            cropper.scaleX(flipp)
            this.flipXValue = !this.flipXValue;
        },
        flipY() {
            let flipp = this.flipYValue === true?1:-1;
            cropper.scaleY(flipp)
            this.flipYValue = !this.flipYValue;
        },

        getResult(result) {
            this.result = result;
        },

        reset() {
            if (!cropper) return
            cropper.reset()
        },

    },
    setup() {
        const serverErrorMessage = ref("");
        const yourErrorHeaderVariable = ref("");
        const language = ref('Select language');
        const files = ref([]);
        const dragActive = ref(false);
        const submitted = ref(false);
        const cropperPopup = ref(false);
        const currentFileIndex = ref(null);
        const pic = ref('');

        const handleFileSelection = (event) => {
            const newFiles = Array.from(event.target.files).map((file) => ({
                dataURL: URL.createObjectURL(file),
                name: file.name,
                uploading: false,
                uploaded: false,
                uploadProgress: 0,
                error: null,
                file: file, // Store the actual File object
            }));

            files.value.push(...newFiles);
        };
        const resetTurnstile = () => {

            if (window.turnstile && typeof window.turnstile.remove === 'function') {
                window.turnstile.remove();
            }

            window.turnstile.render('.cf-turnstile', {
                sitekey: '0x4AAAAAAAYPnENkBR7iR2js',
                callback: (newToken) => {
                    token.value = newToken;
                }
            });
        };

        const retryUpload = (file) => {
            if (!file) {

                alert('No file selected for upload');
                return;
            }
            resetTurnstile();

            uploadFile(file);
        };
        const uploadFiles = async () => {
            submitted.value = true;
            for (let i = 0; i < files.value.length; i++) {
                await uploadFile(files.value[i]);
            }
        };

        const remove = async (index) => {
            files.value.splice(index, 1);
            if (!files.value.length) {
                submitted.value = false;
            }
        };

        const downloadDoc = async (text, filename) => {
            var blob = new Blob([text], { type: 'application/msword' });
            var url = URL.createObjectURL(blob);
            var downloadLink = document.createElement("a");
            downloadLink.href = url;
            downloadLink.download = `result-jpgconverters-com.doc`;

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        };
        const downloadAllDocs = () => {
            files.value.forEach((file) => {
                if (file.text) {
                    downloadDoc(file.text);
                }
            });
        };
        const copyText = async (text) => {
            const el = document.createElement('textarea');
            el.value = text;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            const selected =  document.getSelection().rangeCount > 0  ? document.getSelection().getRangeAt(0) : false;
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            if (selected) {
                document.getSelection().removeAllRanges();
                document.getSelection().addRange(selected);
            }
            setTimeout(function () {
                copyText.classList.remove("active");
            }, 2500);
        };
        /*const downloadAllDocs = () => {
            files.value.forEach((file, index) => {
                if (file.transltion) {
                    const filename = `result-imagetotext-${index + 1}.doc`;
                    downloadDoc(file.transltion, filename);
                }
            });
        };*/

        const translate = async (index,lang,langtext) => {
            let file = files.value[index];
            file.transltionLang = langtext;
            files.value[index] = file;
            language.value = langtext;
            const formData = new FormData();
            formData.append("text", file.text);
            formData.append("to", lang); // Append the actual File object
            const config = {
                headers: { "Content-Type": "multipart/form-data" },
            };

            try {
                // Replace 'your-api-endpoint' with your actual server API endpoint
                const response = await axios.post("/translate-text", formData, config);
                file.transltion = response.data.text
                files.value[index] = file;
            } catch (error) {
                console.error("Error translate:", error);
            }
        };
        const uploadFile = async (file) => {
            const formData = new FormData();
            formData.append("images", file.file); // Append the actual File object

            const config = {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round(
                        (progressEvent.loaded / progressEvent.total) * 100
                    );
                    file.uploading = true;
                    file.uploadProgress = progress;
                },
            };

            try {
                // Replace 'your-api-endpoint' with your actual server API endpoint
                const response = await axios.post("/convert-to-text", formData, config);
                file.uploading = false;
                file.uploaded = true;
                file.uploadProgress = 100;
                file.responseData = response.data;
                file.text = response.data.text || null;

            } catch (error) {
                file.uploading = false;
                file.error = "Error uploading image";
                console.error("Error uploading image:", error);

                if (error.response) {
                    const status = error.response.status;

                    if (status === 500) {
                        serverErrorMessage.value = "Internal Server Error";
                        yourErrorHeaderVariable.value = "Error 500";

                    } else if (status === 422) {
                        serverErrorMessage.value = error.response.data.message || "Unprocessable Entity";
                        yourErrorHeaderVariable.value = "Error 422";
                    }
                    else if (status === 400) {
                        serverErrorMessage.value ="Bad Request";
                        yourErrorHeaderVariable.value = "400";
                    }
                    else if (status === 408) {
                        serverErrorMessage.value ="Request Timeout";
                        yourErrorHeaderVariable.value = "Error 408";
                    }
                    else if (status === 503) {
                        serverErrorMessage.value ="Service Unavailable";
                        yourErrorHeaderVariable.value = "Error 503";
                    }
                }
            }
        };

        const removeAllFiles=()=>{
            files.value=[]
            submitted.value = false;
        }
        const confirmCrop = async () => {
            const dataURL = cropper.getDataURL();
            console.log("Cropped Image Data URL:", dataURL);

            // Update the Data URL and the file object in the files array
            const croppedBlob = dataURLtoBlob(dataURL);
            const newFileName = files.value[currentFileIndex.value].name.replace(/\.[^/.]+$/, '') + '_cropped.png';

            files.value[currentFileIndex.value].dataURL = dataURL;
            files.value[currentFileIndex.value].file = new File([croppedBlob], newFileName, { type: croppedBlob.type });
            files.value[currentFileIndex.value].name = newFileName;

            cropperPopup.value = false;
        };
        const dataURLtoBlob = (dataURL) => {
            const byteString = atob(dataURL.split(',')[1]);
            const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

            const arrayBuffer = new ArrayBuffer(byteString.length);
            const intArray = new Uint8Array(arrayBuffer);

            for (let i = 0; i < byteString.length; i++) {
                intArray[i] = byteString.charCodeAt(i);
            }

            return new Blob([intArray], { type: mimeString });
        };
        const CropperPopupSet = (index) => {
            currentFileIndex.value = index;
            cropperPopup.value = true;
            pic.value = files.value[currentFileIndex.value].dataURL;
            console.log(files.value[currentFileIndex.value].dataURL);
        };
        const dragover = async () => {
            dragActive.value = true;
        };
        const dragleave = async (event) => {
            dragActive.value = false;
        };
        const dropFile = (event) => {
            event.preventDefault();
            dragActive.value = false;
            const newFiles = Array.from(event.dataTransfer.files).map((file) => ({
                dataURL: URL.createObjectURL(file),
                name: file.name,
                uploading: false,
                uploaded: false,
                uploadProgress: 0,
                error: null,
                file: file, // Store the actual File object
            }));

            files.value.push(...newFiles);
        };
        return {
            dragover,
            dragleave,
            dropFile,
            remove,
            translate,
            dragActive,
            language,
            submitted,
            files,
            handleFileSelection,
            uploadFiles,
            downloadDoc,
            cropperPopup,
            CropperPopupSet,
            confirmCrop,
            dataURLtoBlob,
            pic,
            downloadAllDocs,
            serverErrorMessage,
            yourErrorHeaderVariable,
            retryUpload,
            resetTurnstile,
            copyText,
            removeAllFiles
        };
    },
};

</script>
<style scoped>
.flex-grow {
    flex-grow: 1;
}
</style>
