<template>
        <div  v-if="!filesUploaded" class="w-full text-center shadow-xl rounded-md bg-white px-8 pb-10 pt-3">
            <h2 class="text-[24px] font-semibold mb-4">Upload Your Files</h2>
            <div class="dropzone-custom-content mx-auto border-dashed border-gray-400 rounded border p-6" @drop.prevent="dropFile" @dragenter="dropFile" @dragover="dropFile" :class="{ ' bg-pink-800': dragActive }">
                <h2 class="text-[#AAAAAD] text-2xl font-normal">
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-20 h-20 animate-bounce mx-auto" width="20" height="20" viewBox="0 0 511 511" version="1.1">
                        <g id="#ff6123ff">
                            <path fill="#ff6123" opacity="1.00" d=" M 181.43 6.64 C 200.13 1.40 219.77 -0.10 239.09 1.56 C 271.95 4.53 303.76 17.76 329.14 38.84 C 356.70 61.44 376.66 93.45 383.66 128.50 C 421.96 127.96 459.65 146.66 483.31 176.63 C 499.68 196.95 509.33 222.42 511.00 248.44 L 511.00 263.36 C 509.63 283.20 503.38 302.52 493.43 319.69 C 446.07 319.76 398.70 319.71 351.34 319.71 C 308.93 277.31 266.54 234.90 224.13 192.51 C 181.67 234.89 139.32 277.38 96.83 319.74 C 66.74 319.69 36.65 319.74 6.56 319.71 C -2.68 295.26 -0.17 267.32 10.82 243.86 C 21.52 220.77 40.56 200.87 64.76 192.08 C 64.77 179.37 64.75 166.67 64.77 153.96 C 65.82 124.98 75.04 96.33 91.18 72.23 C 112.08 40.47 144.77 16.70 181.43 6.64 Z"/>
                            <path fill="#ff6123" opacity="1.00" d=" M 64.69 447.22 C 117.82 394.06 170.98 340.92 224.13 287.78 C 277.29 340.91 330.45 394.05 383.57 447.23 C 341.04 447.26 298.52 447.22 255.99 447.24 C 255.92 455.83 255.98 464.42 255.96 473.01 C 255.90 478.33 256.45 483.82 254.63 488.94 C 250.99 500.76 239.93 509.73 227.64 511.00 L 220.49 511.00 C 208.37 509.65 197.45 500.92 193.73 489.28 C 191.77 484.06 192.35 478.45 192.29 473.01 C 192.27 464.42 192.32 455.83 192.26 447.24 C 149.73 447.22 107.21 447.26 64.69 447.22 Z"/>
                        </g>
                    </svg>
                    Drop Your Here
                </h2>
                <div class="relative">
                    <div class="inline-flex items-center justify-center w-full px-4">
                        <hr class="w-full h-px my-5 bg-gray-200 border-0 dark:bg-gray-700">
                        <span class="absolute px-3 font-medium text-gray-900 -translate-x-1/2 bg-white left-1/2 dark:text-white dark:bg-gray-900">or</span>
                    </div>
                </div>


                <div  class="flex items-center justify-center w-full px-12">
                    <div class="flex flex-col items-center justify-center gap-1 w-full text-white dark:text-gray-100 py-2">
                        <label for="file" class="w-30 flex flex-col items-center bg-red-600 px-5 py-2 rounded-lg shadow-lg border cursor-pointer text-white">
                            <span class="md:text-2xl font-semibold">UPLOAD TO Convert</span>
                            <input type="file" multiple name="fields"  @change="handleFileSelection" ref="file" id="file" class="hidden" />
                        </label>
                    </div>
                </div>
            </div>

        </div>

        <div class="flex w-full" v-if="this.files.length && !this.submitted">
            <div class="w-full flex flex-wrap mt-4 rounded-lg p-4 w-full">
                <div class="w-full flex justify-between">
                    <div class="md:w-1/2">
                        <h3 class="my-auto ml-4">Images({{this.files.length}})</h3>
                    </div>
                    <div class="md:w-1/2 flex justify-end">
                        <div>
                            <button @click="removeAllFiles" type="button" class="py-2.5 px-3 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-red-600 focus:ring-4 focus:ring-gray-100">Cancel All</button>
                        </div>
                    </div>
                </div>
                <div class="w-full p-3 my-1 flex flex-wrap flex-row" v-for="(file, index) in files">
                    <div class="w-full flex flex-wrap flex-row justify-between gap-2  border-2 border-gray-200 rounded-md p-2  relative">
                        <div class="w-full flex flex-wrap flex-row justify-between">
                            <div class="flex flex-wrap gap-2">
                                <img :src="file.dataURL" class="rounded-lg my-2 h-5 mx-auto object-contain" style="object-fit: cover; width: 60px; height: 55px">
                                <div class="flex flex-col my-auto">
                                    <span class="text-xs font-semibold text-red-600 my-2">{{ file.name.substr(file.name.length - 10) }}</span>
                                    <span class="text-red-600">{{Math.floor(file.file.size/1024)+"KB"}}</span>
                                </div>
                            </div>
                            <div class="flex">
                                <div class="mx-2 flex">
                            <p class="my-auto text-base font-semibold mx-2">Output</p>
                            <select
                                v-model="selectedFormat" @change="handleFormatChange" id="select" class="w-[77px] bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block px-5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 hover:bg-red-50">
                                <option v-for="format in formatOptions" :key="format" :value="format">
                                    {{ format }}
                                </option>
                            </select>
                        </div>
                                <div class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-100">
                                    <button @click="CropperPopupSet(index)" title="Cropper" class="inline-flex items-center top-1 right-1 my-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="22px" height="22px" viewBox="0 0 1920 1920">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M426.667 0h106.666v1386.67H1920v106.66H426.667V0zM320 426.667H0v106.666h320V426.667zm320 0v106.666h746.67V1280h106.66V426.667H640zM1493.33 1600h-106.66v320h106.66v-320z"/>
                                        </svg>
                                    </button>
                                </div>
                                <!-- setting -->
                        <div v-if="selectedFormat === 'PDF'" class="mt-4 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-100">
                            <button @click="PdfSettingPopup = true" title="Setting">
                                <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 24 24" fill="none">
                                    <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </button>
                        </div>
                                <div v-if="selectedFormat !== 'PDF'" class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-100">
                                    <button @click="settingPopup = true" title="Setting">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 24 24" fill="none">
                                            <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </button>
                                </div>
                                <div class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-100">
                                    <button @click="remove(files.indexOf(file))" title="Remove file" class="inline-flex items-center top-1 right-1 my-auto">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="red" height="13px" width="13px" version="1.1" id="Layer_1" viewBox="0 0 492 492" xml:space="preserve">
                                            <g>
                                                <g>
                                                    <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                                                </g>
                                            </g>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="md:w-full flex flex-wrap shadow-lg mt-4 rounded-lg p-4" v-if="this.files.length && this.submitted">
            <div class="md:w-full flex justify-between">
                    <h3 class="my-auto ml-5">Results({{this.files.length}})</h3>
                    <div>
                        <button @click="mergeAllPdfs" :disabled="files.length === 0"  v-if="files.length > 1" type="button" class="py-2.5 px-3 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:ring-4 focus:ring-gray-100">Merge PDF</button>
                        <button @click="removeAllFiles" type="button" class="py-2.5 px-3 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-sm border border-gray-200 hover:bg-gray-100 hover:text-red-600 focus:ring-4 focus:ring-gray-100">Cancel All</button>
                    </div>

                </div>
                <div class="flex flex-wrap justify-between w-full p-3 my-1" v-for="(file,index) in files" :key="index">
                    <div class="flex flex-wrap flex-row w-full justify-between gap-2 border-2 border-gray-200 rounded-md p-2">
                        <div class="flex flex-wrap gap-2">
                            <iframe v-if="file.convertedPdfUrl" :src="file.convertedPdfUrl" width="60px" height="55px"></iframe>
                            <img v-else="file.dataURL" :src="file.dataURL" class="rounded-lg h-5 my-1 mx-auto object-contain" style="object-fit: cover; width: 60px; height: 55px">
                            <div class="flex flex-col my-auto">
                                <span class="text-xs font-semibold text-red-600 my-1">{{ file.name.substr(file.name.length - 10) }}</span>
                                <span class="text-red-600">{{Math.floor(file.file.size/1024)+"KB"}}</span>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="my-auto">
                                <a v-if="file.uploaded" target="_blank" :href="file.dataURL" :download="file.name" class="w-full bg-red-800 p-1 mt-1 text-white rounded hover:bg-red-600">Download</a>
                            </div>
                            <div class="my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center hover:bg-red-100">
                                <button @click="remove(files.indexOf(file))" title="Remove file" class="inline-flex items-center  top-2 right-1">
                                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="red" height="13px" width="13px" version="1.1" id="Layer_1" viewBox="0 0 492 492" xml:space="preserve">
                                            <g>
                                                <g>
                                                    <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                                                </g>
                                            </g>
                                        </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    <!-- loading start -->
    <div v-if="loading" role="status" class="mx-auto">
        <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
            <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
        </svg>
        <span class="sr-only">Loading...</span>
    </div>
    <!-- loading end -->

        <div class="w-full flex justify-end px-2">
            <div class="flex flex-row">
                <div class="mx-2">
                    <button v-if="files.length > 0 && !conversionCompleted" @click="uploadFiles" :disabled="disableConvertButton" :class="{ 'bg-red-300': disableConvertButton, 'bg-red-800': !disableConvertButton, 'hover:bg-red-500 hover:shadow-sm': !disableConvertButton }" class="py-2 px-3 me-2 mb-2 text-sm font-medium text-white border rounded-md focus:outline-none bg-red-800  hover:bg-red-400 focus:ring-4 focus:ring-gray-100">
                        Convert
                        <i class="fa-solid fa-arrow-right"></i>
                    </button>
                    <button v-else-if="conversionCompleted && files.length > 1" @click="downloadAllAsZip" class="py-1.5 px-2 me-2 mb-2 text-sm font-medium text-white border rounded-md focus:outline-none bg-red-700  hover:bg-red-400 focus:ring-4 focus:ring-gray-100">
                        Download All
                    </button>
                </div>

            </div>
        </div>
        <!-- option pop up start.. -->
        <div v-if="settingPopup" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
            <div class="bg-white p-4 rounded-lg w-[720px] relative">
                <div class="flex my-4">
                    <!--cross button..-->
                    <button @click="settingPopup = false" class="inline-flex items-center top-1 right-1 my-auto absolute top-4 right-4   mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full  justify-center hover:bg-red-50">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="red" height="13px" width="13px" version="1.1" id="Layer_1" viewBox="0 0 492 492" xml:space="preserve">
                            <g>
                                <g>
                                    <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                                </g>
                            </g>
                        </svg>
                    </button>

                    <div class="my-auto mx-2 w-10 h-10 rounded-md border-2 flex items-center justify-center">
                        <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 24 24" fill="none">
                            <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                    <div class="mx-2">
                        <p class="text-lg font-semibold">Options</p>
                        <span class="text-sm font-medium">WEBP to JPG Converter</span>
                    </div>
                </div>
                <hr>
                <div class="my-5">
                    <div class="mb-1 flex justify-around">
                        <label class="my-auto block text-black dark:text-white">
                            Quality <span class="text-meta-1">*</span>
                        </label>
                        <!--                        <input v-model="quality" type="number" id="quality" name="quality" class="w-2/3 mr-2 rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary" min="0" max="1" step="1" />-->

                        <select v-model="quality" class="w-2/3">
                            <option value="1">High</option>
                            <option value="0.5">Medium</option>
                            <option value="0">Low</option>
                        </select>


                    </div>
                    <div class="w-[66%] text-left ml-[26%] mb-1">
                        <span class="text-[12px] leading-normal">
                            JPEg compression level from 1 (lowest image quality and highest compression) to 100 (best quality but least effective compression). The default is to estimate the quality based on your input image.
                        </span>
                    </div>
                </div>
                <hr>
                <div class="w-[90%] mx-auto">
                    <button @click="settingPopup = false" class="w-[46%] mt-4 px-4 mx-2 py-1.5 text-black border-2 rounded-lg cursor-pointer hover:bg-red-50">Cancel</button>
                    <button @click="settingPopup = false" class="w-[46%] mt-4 px-4 py-1.5 bg-red-800 text-white rounded-lg cursor-pointer hover:bg-red-600">Confirm</button>
                </div>
            </div>
        </div>
        <!-- option pop up end.. -->

<!-- Pdf SettingPopUp start -->
    <!--pop up start..-->
    <div v-if="PdfSettingPopup" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
        <div class="bg-white p-4 rounded-lg w-[720px] relative ">
            <div class="flex my-4 ">
                <!--cross button..-->
                <div class="my-auto mx-2 w-10 h-10 rounded-md border-2 flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22px" height="22px" viewBox="0 0 24 24" fill="none">
                        <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z" stroke="#292D32" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div class="mx-2">
                    <p class="text-lg font-semibold">Options</p>
                    <span class="text-sm font-medium">JPG to PDF Converter</span>
                </div>
                <div class="alert-box-close absolute right-2 my-auto mx-2 bg-[#F5F7F9] border-0 w-10 h-10 rounded-full flex items-center justify-center">
                    <button @click="PdfSettingPopup = false" title="Remove file" class="inline-flex items-center top-1 right-1 my-auto ">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="red" height="13px" width="13px" version="1.1" id="Layer_1" viewBox="0 0 492 492" xml:space="preserve">
                                <g>
                                    <g>
                                        <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                                    </g>
                                </g>
                            </svg>
                    </button>
                </div>
            </div>
            <hr>
            <div class="my-5">
                <div class="mb-1 flex justify-around">
                    <label class="my-auto block text-black dark:text-white">
                        Page Size <span class="text-meta-1">*</span>
                    </label>
                    <select id="PageSize" name="PageSize" v-model="PageSize" class="w-2/3 rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                        <option value="fit">Fit</option>
                        <option value="a3">A3</option>
                        <option value="a4">A4</option>
                        <option value="a5">A5</option>
                        <option value="letter">Letter</option>
                        <option value="legal">Legal</option>
                        <option value="b4">B4</option>
                        <option value="b5">B5</option>
                    </select>
                </div>

                <div class="mb-1 my-4 flex justify-around">
                    <label class="my-auto block text-black dark:text-white">
                        Page Orientation <span class="text-meta-1">*</span>
                    </label>
                    <div class="w-2/3">
                        <div class="flex items-center mb-4">
                            <input id="portrait-radio" type="radio" v-model="PageOrientation" value="portrait" class="w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="portrait-radio" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Portrait</label>
                        </div>
                        <div class="flex items-center">
                            <input id="landscape-radio" type="radio" v-model="PageOrientation" value="landscape" class="w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600">
                            <label for="landscape-radio" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Landscape</label>
                        </div>
                    </div>
                </div>

                <div class="mb-1 my-5 flex justify-around">
                    <label class="my-auto block text-black dark:text-white">
                        Margin <span class="text-meta-1">*</span>
                    </label>
                    <select id="PageMargin" name="PageMargin" v-model="PageMargin" class="w-2/3 rounded-lg border-[1.5px] border-stroke bg-transparent py-2 px-5 font-medium outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                        <option value="0">No Margin</option>
                        <option value="10">Small</option>
                        <option value="30">Big</option>
                    </select>
                </div>

            </div>
            <hr>
            <div class="w-[90%] mx-auto">
                <button @click="PdfSettingPopup = false" class="w-[46%] mt-4 px-4 mx-2 py-1.5 text-black border-2 rounded-lg cursor-pointer hover:bg-red-50">Cancel</button>
                <button @click="PdfSettingPopup = false" class="w-[46%] mt-4 px-4 py-1.5 bg-red-800 text-white rounded-lg cursor-pointer hover:bg-red-600">Confirm</button>
            </div>
        </div>
    </div>
    <!--pop up end..-->
<!-- Pdf SettingPopup end-->

        <!-- cropper Pop up start.. -->
        <div v-if="cropperPopup" class="cropper-popup md:fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 flex flex-col">
            <div class="w-[720px] relative">
                <button class="inline-flex items-center my-auto absolute top-5 right-5 z-50 px-3 py-3 hover:bg-red-50 rounded-full  bg-white" @click="cropperPopup = false">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="red" height="13px" width="13px" version="1.1" id="Layer_1" viewBox="0 0 492 492" xml:space="preserve">
                        <g>
                            <g>
                                <path d="M300.188,246L484.14,62.04c5.06-5.064,7.852-11.82,7.86-19.024c0-7.208-2.792-13.972-7.86-19.028L468.02,7.872    c-5.068-5.076-11.824-7.856-19.036-7.856c-7.2,0-13.956,2.78-19.024,7.856L246.008,191.82L62.048,7.872    c-5.06-5.076-11.82-7.856-19.028-7.856c-7.2,0-13.96,2.78-19.02,7.856L7.872,23.988c-10.496,10.496-10.496,27.568,0,38.052    L191.828,246L7.872,429.952c-5.064,5.072-7.852,11.828-7.852,19.032c0,7.204,2.788,13.96,7.852,19.028l16.124,16.116    c5.06,5.072,11.824,7.856,19.02,7.856c7.208,0,13.968-2.784,19.028-7.856l183.96-183.952l183.952,183.952    c5.068,5.072,11.824,7.856,19.024,7.856h0.008c7.204,0,13.96-2.784,19.028-7.856l16.12-16.116    c5.06-5.064,7.852-11.824,7.852-19.028c0-7.204-2.792-13.96-7.852-19.028L300.188,246z"/>
                            </g>
                        </g>
                    </svg>
                </button>
                <div class="bg-white p-4 rounded-lg w-[720px] relative">
                    <VuePictureCropper
                        :aspectRatio="1/1"
                        :boxStyle="{
                        width: '100%',
                        height: '500px',
                        backgroundColor: '#f8f8f8',
                        margin: 'auto'
                    }"
                        :img="pic"
                        :options="options"
                        @cropend="getResult"
                        ref="cropperRef"
                    />

                    <div class="flex flex-wrap justify-center flex-col">
                        <div class="inline-flex rounded-md shadow-sm my-2 mx-auto" role="group">
                            <button @click="rotateTo(-45)" type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-l-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 gap-2">
                                <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" transform="matrix(1, 0, 0, 1, 0, 0)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" d="M20.4852814,14 L12,22.4852814 L3.51471863,14 L12,5.51471863 L20.4852814,14 Z M6.34314575,14 L12,19.6568542 L17.6568542,14 L12,8.34314575 L6.34314575,14 Z M4.15672797,4.28593933 C6.18580699,2.22227176 8.98673097,1 12,1 C15.9721115,1 19.5752376,3.12390371 21.5277839,6.49927404 L19.7965722,7.50072596 C18.1979627,4.73720691 15.2508538,3 12,3 C9.38603956,3 6.96846584,4.12319378 5.29042786,6 L8,6 L8,8 L2.40310647,8 L2.00227469,2.0674107 L3.99772531,1.9325893 L4.15672797,4.28593933 Z"></path> </g></svg>
                                Rotate Left
                            </button>
                            <button @click="reset" type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border-t border-b border-gray-900 hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 gap-2">
                                <svg class="w-3 h-3"  fill="currentColor" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M960 0v213.333c411.627 0 746.667 334.934 746.667 746.667S1371.627 1706.667 960 1706.667 213.333 1371.733 213.333 960c0-197.013 78.4-382.507 213.334-520.747v254.08H640V106.667H53.333V320h191.04C88.64 494.08 0 720.96 0 960c0 529.28 430.613 960 960 960s960-430.72 960-960S1489.387 0 960 0" fill-rule="evenodd"></path> </g></svg>
                                Reset
                            </button>
                            <button @click="rotateTo(45)"  type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-r-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 gap-2">
                                <svg class="w-4 h-4" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" d="M3.04477723,14 L11.5300586,5.51471863 L20.01534,14 L11.5300586,22.4852814 L3.04477723,14 Z M17.1869129,14 L11.5300586,8.34314575 L5.87320435,14 L11.5300586,19.6568542 L17.1869129,14 Z M19.3733306,4.28593933 L19.5323333,1.9325893 L21.5277839,2.0674107 L21.1269521,8 L15.5300586,8 L15.5300586,6 L18.2396307,6 C16.5615928,4.12319378 14.144019,3 11.5300586,3 C8.27920479,3 5.33209587,4.73720691 3.73348642,7.50072596 L2.00227469,6.49927404 C3.954821,3.12390371 7.5579471,1 11.5300586,1 C14.5433276,1 17.3442516,2.22227176 19.3733306,4.28593933 Z"></path> </g></svg>
                                Rotate Right
                            </button>
                        </div>
                        <div class="inline-flex rounded-md shadow-sm my-2 mx-auto" role="group">
                            <button @click="flipY" type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-gray-900 rounded-l-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
                                <svg class="w-3 h-3" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="m 13 5 c 0 -0.265625 -0.105469 -0.519531 -0.292969 -0.707031 l -4 -4 c -0.390625 -0.3906252 -1.023437 -0.3906252 -1.414062 0 l -4 4 c -0.390625 0.390625 -0.390625 1.023437 0 1.414062 s 1.023437 0.390625 1.414062 0 l 2.292969 -2.292969 v 9.171876 l -2.292969 -2.292969 c -0.390625 -0.390625 -1.023437 -0.390625 -1.414062 0 s -0.390625 1.023437 0 1.414062 l 4 4 c 0.390625 0.390625 1.023437 0.390625 1.414062 0 l 4 -4 c 0.1875 -0.1875 0.292969 -0.441406 0.292969 -0.707031 s -0.105469 -0.519531 -0.292969 -0.707031 c -0.390625 -0.390625 -1.023437 -0.390625 -1.414062 0 l -2.292969 2.292969 v -9.171876 l 2.292969 2.292969 c 0.390625 0.390625 1.023437 0.390625 1.414062 0 c 0.1875 -0.1875 0.292969 -0.441406 0.292969 -0.707031 z m 0 0" fill="currentColor"></path> </g></svg>
                                Flip Vertical
                            </button>
                            <button @click="flipX"  type="button" class="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-transparent border border-l-0 border-gray-900 rounded-r-lg hover:bg-gray-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-gray-500 focus:bg-gray-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700 gap-2">
                                <svg class="w-3 h-3" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="m 5 3 c -0.265625 0 -0.519531 0.105469 -0.707031 0.292969 l -4 4 c -0.3906252 0.390625 -0.3906252 1.023437 0 1.414062 l 4 4 c 0.390625 0.390625 1.023437 0.390625 1.414062 0 s 0.390625 -1.023437 0 -1.414062 l -2.292969 -2.292969 h 9.171876 l -2.292969 2.292969 c -0.390625 0.390625 -0.390625 1.023437 0 1.414062 s 1.023437 0.390625 1.414062 0 l 4 -4 c 0.390625 -0.390625 0.390625 -1.023437 0 -1.414062 l -4 -4 c -0.1875 -0.1875 -0.441406 -0.292969 -0.707031 -0.292969 s -0.519531 0.105469 -0.707031 0.292969 c -0.390625 0.390625 -0.390625 1.023437 0 1.414062 l 2.292969 2.292969 h -9.171876 l 2.292969 -2.292969 c 0.390625 -0.390625 0.390625 -1.023437 0 -1.414062 c -0.1875 -0.1875 -0.441406 -0.292969 -0.707031 -0.292969 z m 0 0" fill="currentColor"></path> </g></svg>
                                Flip Horizontal
                            </button>
                        </div>

                    </div>
                    <div class="w-full flex justify-center">
                        <button @click="cropperPopup = false" class="w-[100px] md:w-[46%] mt-4 px-4 mx-2 py-1.5 text-black border-2 rounded-lg cursor-pointer hover:bg-red-50">Cancel</button>
                        <button @click="confirmCrop" class="w-[100px] md:w-[46%] mt-4 px-4 py-1.5 bg-red-800 text-white rounded-lg cursor-pointer hover:bg-red-600">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- cropper Pop up end.. -->

</template>
<script>
import {computed, ref} from "vue";
import axios from "axios";
import JSZip from 'jszip';
import VuePictureCropper, { cropper }  from 'vue-picture-cropper';
import 'cropperjs/dist/cropper.css';
import {PDFDocument} from "pdf-lib";
import PSD from 'psd.js';
import * as UTIF from 'utif';
import heic2any from 'heic2any';
export default{
    props: {
        translations:Object,
        files: Array
    },
    components: {
        VuePictureCropper,
    },
    data() {
        return {
            options: {
                viewMode: 2,
                dragMode: 'crop',
                aspectRatio: NaN,
            },
            cropperPopup: false,
            pic: '',
            result: null,
            files: [],
            currentFileIndex: null,
            convertedPdfUrl: null,
            quality: 1,
            width: '',
            height: '',

        };
    },
    methods: {
        handleFileSelection(event) {
            const newFiles = Array.from(event.target.files).map((file) => ({
                dataURL: URL.createObjectURL(file),
                name: file.name,
                type: file.type,
                uploading: false,
                uploaded: false,
                uploadProgress: 0,
                error: null,
                file: file, // Store the actual File object
                pdfURL: null,
                convertedPdfUrl: null,
            }));

            for (const file of newFiles) {
                this.uploadFile(file, this.quality); // Convert each file to PDF
            }

            this.files.push(...newFiles);
            this.filesUploaded = true;
        },

        rotateTo(e) {
            cropper.rotate(e)
            // Reset last selection and results
        },

        flipX() {
            let flipp = this.flipXValue === true?1:-1;
            cropper.scaleX(flipp)
            this.flipXValue = !this.flipXValue;
        },
        flipY() {
            let flipp = this.flipYValue === true?1:-1;
            cropper.scaleY(flipp)
            this.flipYValue = !this.flipYValue;
        },

        getResult(result) {
            this.result = result;
        },

        reset() {
            if (!cropper) return
            cropper.reset()
        },

    },
    setup() {
        const files = ref([]);
        const dragActive = ref(false);
        const submitted = ref(false);
        const settingPopup = ref(false);
        const PdfSettingPopup = ref(false);
        const cropperPopup = ref(false);
        const conversionCompleted = ref(false);
        const disableConvertButton = ref(false);
        const width = ref('');
        const height = ref('');
        const quality = ref(1);
        const convertedPdfUrl = ref("");
        const fileUrl = ref("");
        const currentFileIndex = ref(null);
        const pic = ref('');
        const filesUploaded = ref(false);
        const selectedFormat = ref('JPG'); // Default format
        const formatOptions = ref(['JPG', 'SVG', 'PNG', 'PDF']); // All format options
        const PageSize = ref('fit');
        const PageOrientation = ref('portrait');
        const PageMargin = ref('0');
        const loading = ref(false);

        const handleFileSelection = async (event) => {
            // document.querySelector('#linkMeTwo').classList.toggle('hidden')

            const linkMeTwoDiv = document.querySelector('#linkMeTwo');
            linkMeTwoDiv.classList.toggle('hidden');

            // Select the second div
            const secondDiv = document.querySelector('#secondDiv');

            // If the first div is now hidden, make the second div full width
            if (linkMeTwoDiv.classList.contains('hidden')) {
                secondDiv.classList.remove('md:w-1/2');
                secondDiv.classList.add('w-full');
            } else {
                // If the first div is visible, revert the second div to half width
                secondDiv.classList.remove('w-full');
                secondDiv.classList.add('md:w-1/2');
            }

            const newFiles = Array.from(event.target.files);
            const hasPsdFiles = newFiles.some(file => file.name.toLowerCase().endsWith('.psd'));
            const hasTiffFiles = newFiles.some(file => file.name.toLowerCase().endsWith('.tiff') || file.name.toLowerCase().endsWith('.tif'));
            const hasHeicFiles = newFiles.some(file => file.name.toLowerCase().endsWith('.heic'));

            filesUploaded.value = true;

            if (hasPsdFiles) {
                // Handle PSD files
                loading.value = true;
                for (const file of newFiles) {
                    if (file.name.toLowerCase().endsWith('.psd')) {
                        const objectURL = URL.createObjectURL(file);
                        try {
                            const psdFile = await PSD.fromURL(objectURL);
                            const img = await psdFile.image.toPng();

                            const canvas = document.createElement('canvas');
                            canvas.width = img.width;
                            canvas.height = img.height;
                            const ctx = canvas.getContext('2d');
                            ctx.drawImage(img, 0, 0);

                            let qualityValue = 1;
                            if (quality.value === 0.5) {
                                qualityValue = 0.3;
                            } else if (quality.value === 0) {
                                qualityValue = 0.1;
                            }

                            canvas.toBlob((blob) => {
                                const newFileName = file.name.replace(/\.[^/.]+$/, '') + ".heif";
                                const jpgFile = new File([blob], newFileName, { type: 'image/jpeg', lastModified: new Date() });

                                const newFileObject = {
                                    dataURL: URL.createObjectURL(jpgFile),
                                    name: newFileName,
                                    uploading: false,
                                    uploaded: true,
                                    uploadProgress: 100,
                                    error: null,
                                    file: jpgFile,
                                };

                                files.value.push(newFileObject);
                                URL.revokeObjectURL(objectURL);
                            }, 'image/jpeg', qualityValue);

                        } catch (error) {
                            console.error("Error converting PSD to JPG:", error);
                            const newFileObject = {
                                dataURL: objectURL,
                                name: file.name,
                                uploading: false,
                                uploaded: false,
                                uploadProgress: 0,
                                error: "Error converting image",
                                file: file,
                            };

                            files.value.push(newFileObject);
                        }
                    }
                }
                loading.value = false;
            }

            if (hasTiffFiles) {
                // Handle TIFF files
                const tiffFiles = await Promise.all(
                    newFiles.filter(file => file.name.toLowerCase().endsWith('.tiff') || file.name.toLowerCase().endsWith('.tif')).map(async (file) => {
                        const objectURL = URL.createObjectURL(file);

                        try {
                            const response = await fetch(objectURL);
                            const arrayBuffer = await response.arrayBuffer();

                            const ifds = UTIF.decode(arrayBuffer);
                            UTIF.decodeImage(arrayBuffer, ifds[0]);
                            const firstImage = ifds[0];

                            const width = Math.floor(firstImage.width);
                            const height = Math.floor(firstImage.height);

                            if (isNaN(width) || isNaN(height)) {
                                throw new Error('Invalid image dimensions');
                            }

                            const rgba = UTIF.toRGBA8(firstImage);
                            const canvas = document.createElement('canvas');
                            canvas.width = width;
                            canvas.height = height;
                            const ctx = canvas.getContext('2d');
                            const imageData = ctx.createImageData(width, height);
                            imageData.data.set(rgba);
                            ctx.putImageData(imageData, 0, 0);

                            let qualityValue = 1;
                            if (quality.value === '0.5') {
                                qualityValue = 0.3;
                            } else if (quality.value === '0') {
                                qualityValue = 0.1;
                            }

                            return new Promise((resolve) => {
                                canvas.toBlob((blob) => {
                                    const newFileName = file.name.replace(/\.[^/.]+$/, '') + '.heif'; // Convert TIFF to JPG
                                    const jpgFile = new File([blob], newFileName, { type: 'image/jpeg', lastModified: new Date() });

                                    resolve({
                                        dataURL: URL.createObjectURL(jpgFile),
                                        name: newFileName,
                                        file: jpgFile,
                                        uploading: false,
                                        uploaded: true,
                                        uploadProgress: 100,
                                        error: null,
                                    });

                                    URL.revokeObjectURL(objectURL);
                                }, 'image/jpeg', qualityValue);
                            });

                        } catch (error) {
                            console.error("Error converting TIFF to JPG:", error);
                            return {
                                dataURL: objectURL,
                                name: file.name,
                                uploading: false,
                                uploaded: false,
                                uploadProgress: 0,
                                error: "Error converting image",
                                file: file,
                            };
                        }
                    })
                );

                files.value.push(...tiffFiles); // Add the new TIFF files
            }

            if (hasHeicFiles) {
                const heicFiles = await Promise.all(
                    newFiles.filter(file => file.name.toLowerCase().endsWith('.heic')).map(async (file) => {
                        const objectURL = URL.createObjectURL(file);

                        try {
                            const blob = await heic2any({
                                blob: file,
                                toType: 'image/jpeg',
                                quality: parseFloat(quality.value)
                            });

                            const img = new Image();
                            img.src = URL.createObjectURL(blob);
                            await new Promise(resolve => { img.onload = resolve; });

                            const width = img.width;
                            const height = img.height;

                            if (isNaN(width) || isNaN(height)) {
                                throw new Error('Invalid image dimensions');
                            }

                            const canvas = document.createElement('canvas');
                            canvas.width = width;
                            canvas.height = height;
                            const ctx = canvas.getContext('2d');
                            ctx.drawImage(img, 0, 0, width, height);

                            return new Promise((resolve) => {
                                canvas.toBlob((jpegBlob) => {
                                    const newFileName = file.name.replace(/\.[^/.]+$/, '') + '.heif'; // Replace existing extension with .jpg
                                    const jpgFile = new File([jpegBlob], newFileName, {
                                        type: 'image/jpeg',
                                        lastModified: new Date(),
                                    });

                                    resolve({
                                        dataURL: URL.createObjectURL(jpgFile),
                                        name: newFileName,
                                        file: jpgFile,
                                        uploading: false,
                                        uploaded: true,
                                        uploadProgress: 100,
                                        error: null,
                                    });

                                    URL.revokeObjectURL(objectURL);
                                }, 'image/jpeg');
                            });
                        } catch (error) {
                            console.error('Error converting HEIC image:', error);
                            return {
                                dataURL: objectURL,
                                name: file.name,
                                file: file,
                                uploading: false,
                                uploaded: false,
                                uploadProgress: 0,
                                error: 'Error converting HEIC image',
                            };
                        }
                    })
                );

                files.value.push(...heicFiles);
            }

            // Handle other file types
            if (!hasPsdFiles && !hasTiffFiles && !hasHeicFiles) {
                const newFilesData = newFiles.map(file => ({
                    dataURL: URL.createObjectURL(file),
                    name: file.name,
                    type: file.type,
                    uploading: false,
                    uploaded: false,
                    uploadProgress: 0,
                    error: null,
                    file: file,
                }));

                files.value.push(...newFilesData);
                updateFormatOptions(newFilesData); // Call your format options update logic
            }

            disableConvertButton.value = false; // Enable the convert button
        };

        const updateFormatOptions = (newFiles) => {
            if (newFiles.length > 0) {
                const fileType = newFiles[0].file.type;
                const fileName = newFiles[0].file.name;
                const fileExtension = fileName.split('.').pop().toLowerCase(); // Get file extension
                if (fileExtension === 'png' || fileExtension === 'webp') {
                    formatOptions.value = ['JPG', 'PDF'];
                }
                else if (fileExtension === 'jfif'){
                    formatOptions.value = ['JPG'];
                }
                else if (fileExtension === 'jpg') {
                    formatOptions.value = ['SVG', 'PNG', 'PDF'];
                }
                else if (fileExtension === 'heif') {
                    formatOptions.value = ['JPG'];
                }
                else if (fileExtension === 'jpeg'){
                    formatOptions.value = ['JPG'];
                }

                // Reset the selected format when options change
                selectedFormat.value = formatOptions.value[0];
            }
        };

        const handleFormatChange = () => {
            console.log('Selected format:', selectedFormat.value);
        };

        const removeAllFiles = () => {
            // document.querySelector('#linkMeTwo').classList.toggle('hidden')

            const linkMeTwoDiv = document.querySelector('#linkMeTwo');
            linkMeTwoDiv.classList.toggle('hidden');

            // Select the second div
            const secondDiv = document.querySelector('#secondDiv');

            // If the first div is now hidden, make the second div full width
            if (linkMeTwoDiv.classList.contains('hidden')) {
                secondDiv.classList.remove('md:w-1/2');
                secondDiv.classList.add('w-full');
            } else {
                // If the first div is visible, revert the second div to half width
                secondDiv.classList.remove('w-full');
                secondDiv.classList.add('md:w-1/2');
            }

            files.value = []; // Empty the files array
            submitted.value = false;
            conversionCompleted.value = false; // Reset conversion completed flag
            filesUploaded.value = false;
        };

        const uploadFiles = async () => {
            submitted.value=true;
            for (let i = 0;i<files.value.length;i++){

                const file = files.value[i];
                if (file.name.toLowerCase().endsWith('.jpg')) {
                    if (selectedFormat.value === 'SVG') {
                        await uploadFileJpgToSVG(file);
                    }
                    else if (selectedFormat.value === 'PNG') {
                        await uploadFileJpgToPng(file);
                    }
                    else if (selectedFormat.value === 'PDF') {
                        await uploadFileJpgToPDF(file);
                    }

                }

                else if (file.name.toLowerCase().endsWith('.png')) {
                    if (selectedFormat.value === 'JPG') {
                        await uploadFilePngToJpg(file);
                    }
                    if (selectedFormat.value === 'PDF') {
                        await uploadFilePngToPDF(file);
                    }
                }

                else if (file.name.toLowerCase().endsWith('.webp')) {
                    if (selectedFormat.value === 'JPG') {
                        await uploadFileWebpToJpg(file);
                    }
                    if (selectedFormat.value === 'PDF') {
                        await uploadFileWebpToPDF(file);
                    }
                }

                else if (file.name.toLowerCase().endsWith('.avif')) {
                    if (selectedFormat.value === 'JPG') {
                        await uploadFileAvif(file);
                    }
                }

                else if (file.name.toLowerCase().endsWith('.jfif')) {
                    if (selectedFormat.value === 'JPG') {
                        await uploadFileJfifToJpg(file);
                    }
                }

                else if (file.name.toLowerCase().endsWith('.jpeg')) {
                    if (selectedFormat.value === 'JPG') {
                        await uploadFileJpegToJpg(file);
                    }
                }
                else if (file.name.toLowerCase().endsWith('.heif')) {
                    if (selectedFormat.value === 'JPG') {
                        await uploadFileHeifToJpg(file);
                    }
                    if (selectedFormat.value === 'SVG') {
                        await uploadFileHeifToSVG(file);
                    }
                    if (selectedFormat.value === 'PNG') {
                        await uploadFileHeifToPng(file);
                    }
                    if (selectedFormat.value === 'PDF') {
                        await uploadFileHeifToPDF(file);
                    }
                }

                else {
                    console.error("Unsupported file format:", file.name);
                }
                conversionCompleted.value = true;
            }
        };

        const download =async(index) => {
            alert('download')
        };
        const downloadAllAsZip = async () => {
            const zip = new JSZip();

            for (const file of files.value) {
                const response = await fetch(file.dataURL);
                const blob = await response.blob();
                zip.file(file.name, blob);
            }

            const zipBlob = await zip.generateAsync({ type: 'blob' });

            // Create a URL for the Blob
            const url = URL.createObjectURL(zipBlob);

            // Create a temporary anchor element
            const a = document.createElement('a');
            a.href = url;
            a.download = 'converted_files.zip';

            // Append the anchor to the body (not visible)
            document.body.appendChild(a);

            // Programmatically click the anchor to trigger the download
            a.click();

            // Remove the anchor from the document
            document.body.removeChild(a);

            // Revoke the Blob URL to release memory
            URL.revokeObjectURL(url);
        };
        const showDownloadAllButton = computed(() => {
            const jpgFiles = files.value.filter(file => file.name.toLowerCase().endsWith('.jpg') || file.name.toLowerCase().endsWith('.jpeg'));
            return jpgFiles.length > 1;
        });
        const remove =async(index) => {
            files.value.splice(index, 1);
            if (!files.value.length) {
                submitted.value =false;
                filesUploaded.value = false;
                // document.querySelector('#linkMeTwo').classList.toggle('hidden')

                const linkMeTwoDiv = document.querySelector('#linkMeTwo');
                linkMeTwoDiv.classList.toggle('hidden');

                // Select the second div
                const secondDiv = document.querySelector('#secondDiv');

                // If the first div is now hidden, make the second div full width
                if (linkMeTwoDiv.classList.contains('hidden')) {
                    secondDiv.classList.remove('md:w-1/2');
                    secondDiv.classList.add('w-full');
                } else {
                    // If the first div is visible, revert the second div to half width
                    secondDiv.classList.remove('w-full');
                    secondDiv.classList.add('md:w-1/2');
                }
            }
        };

        /*pngTOJpg*/
        const uploadFilePngToJpg = async (file) => {
            file.uploading = true;
            file.uploadProgress = 0;

            const objectURL = URL.createObjectURL(file.file);
            const image = new Image();
            image.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0);

                let qualityValue = 1;

                if (quality.value === '0.5') {
                    qualityValue = 0.3;
                } else if (quality.value === '0') {
                    qualityValue = 0.1; // Low quality
                }

                canvas.toBlob((blob) => {
                    const newFileName = file.name.replace(/\.[^/.]+$/, "") + ".jpg"; // Replace existing extension with .jpg
                    const jpgFile = new File([blob], newFileName, { type: 'image/jpeg', lastModified: new Date() });

                    setTimeout(() => {
                        file.uploadProgress = 100;
                        file.uploading = false;
                        file.uploaded = true;

                        file.dataURL = URL.createObjectURL(jpgFile);
                        file.name = newFileName;
                        URL.revokeObjectURL(objectURL);
                    }, 500);
                }, 'image/jpeg', qualityValue);
            };
            image.src = objectURL;

            image.onerror = () => {
                console.error("Error loading image for conversion.");
                file.uploading = false;
                file.error = "Error converting image";
            };
        };
        /*JpgToSVG*/
        const uploadFileJpgToSVG = async (file) => {
            file.uploading = true;
            file.uploadProgress = 0;

            try {
                const reader = new FileReader();
                reader.onload = () => {
                    const img = new Image();
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        canvas.width = img.width;
                        canvas.height = img.height;

                        ctx.drawImage(img, 0, 0, img.width, img.height);

                        let qualityValue = 1;

                        if (quality.value === '0.5') {
                            qualityValue = 0.3;
                        } else if (quality.value === '0') {
                            qualityValue = 0.1; // Low quality
                        }
                        const dataURL = canvas.toDataURL('image/jpeg', qualityValue);

                        const svgString = `<svg width="${img.width}" height="${img.height}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><image xlink:href="${dataURL}" width="${img.width}" height="${img.height}" /></svg>`;

                        const svgBlob = new Blob([svgString], { type: 'image/svg+xml' });

                        setTimeout(() => {
                            file.uploadProgress = 100;
                            file.uploading = false;
                            file.uploaded = true;

                            file.dataURL = URL.createObjectURL(svgBlob);
                            file.name = file.name.replace(/\.[^/.]+$/, "") + ".svg";

                            console.log('Quality value after conversion:', quality.value);
                        }, 500);
                    };
                    img.src = reader.result;
                };
                reader.readAsDataURL(file.file);
            } catch (error) {
                console.error("Error converting image:", error);
                file.uploading = false;
                file.error = "Error converting image";
            }
        };
        /*WebpToJpg*/
        const uploadFileWebpToJpg = async (file) => {
                    file.uploading = true;
                    file.uploadProgress = 0;

                    const objectURL = URL.createObjectURL(file.file);
                    const image = new Image();
                    image.onload = () => {
                        const canvas = document.createElement('canvas');
                        canvas.width = image.width;
                        canvas.height = image.height;

                        const ctx = canvas.getContext('2d');
                        ctx.drawImage(image, 0, 0);

                        let qualityValue = 1;

                        if (quality.value === '0.5') {
                            qualityValue = 0.3;
                        } else if (quality.value === '0') {
                            qualityValue = 0.1; // Low quality
                        }

                        canvas.toBlob((blob) => {
                            const newFileName = file.name.replace(/\.[^/.]+$/, "") + ".jpg"; // Replace existing extension with .jpg
                            const jpgFile = new File([blob], newFileName, { type: 'image/jpeg', lastModified: new Date() });

                            setTimeout(() => {
                                file.uploadProgress = 100;
                                file.uploading = false;
                                file.uploaded = true;

                                file.dataURL = URL.createObjectURL(jpgFile);
                                file.name = newFileName;
                                URL.revokeObjectURL(objectURL);
                            }, 500);
                        }, 'image/jpeg', qualityValue);
                    };
                    image.src = objectURL;

                    image.onerror = () => {
                        console.error("Error loading image for conversion.");
                        file.uploading = false;
                        file.error = "Error converting image";
                    };
                };
        /*JpgToPng*/
        const uploadFileJpgToPng = async (file) => {
            file.uploading = true;
            file.uploadProgress = 0;

            const objectURL = URL.createObjectURL(file.file);
            const image = new Image();
            image.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0);

                let qualityValue = 1;

                if (quality.value === '0.5') {
                    qualityValue = 0.3;
                } else if (quality.value === '0') {
                    qualityValue = 0.1; // Low quality
                }

                canvas.toBlob((blob) => {
                    const newFileName = file.name.replace(/\.[^/.]+$/, "") + ".png"; // Replace existing extension with .jpg
                    const jpgFile = new File([blob], newFileName, { type: 'image/jpeg', lastModified: new Date() });

                    setTimeout(() => {
                        file.uploadProgress = 100;
                        file.uploading = false;
                        file.uploaded = true;

                        file.dataURL = URL.createObjectURL(jpgFile);
                        file.name = newFileName;
                        URL.revokeObjectURL(objectURL);
                    }, 500);
                }, 'image/jpeg', qualityValue);
            };
            image.src = objectURL;

            image.onerror = () => {
                console.error("Error loading image for conversion.");
                file.uploading = false;
                file.error = "Error converting image";
            };
        };

        const uploadFileAvif = async (file) => {
            file.uploading = true;
            file.uploadProgress = 0;

            const objectURL = URL.createObjectURL(file.file);
            const image = new Image();
            image.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0);

                let qualityValue = 1;

                if (quality.value === '0.5') {
                    qualityValue = 0.3;
                } else if (quality.value === '0') {
                    qualityValue = 0.1; // Low quality
                }

                canvas.toBlob((blob) => {
                    const newFileName = file.name.replace(/\.[^/.]+$/, "") + ".jpg"; // Replace existing extension with .jpg
                    const jpgFile = new File([blob], newFileName, { type: 'image/jpeg', lastModified: new Date() });

                    setTimeout(() => {
                        file.uploadProgress = 100;
                        file.uploading = false;
                        file.uploaded = true;

                        file.dataURL = URL.createObjectURL(jpgFile);
                        file.name = newFileName;
                        URL.revokeObjectURL(objectURL);
                    }, 500);
                }, 'image/jpeg', qualityValue);
            };
            image.src = objectURL;

            image.onerror = () => {
                console.error("Error loading image for conversion.");
                file.uploading = false;
                file.error = "Error converting image";
            };
        };

        const uploadFileJpgToPDF = async (file) => {
            file.uploading = true;
            file.uploadProgress = 0;
            disableConvertButton.value = false;
            const objectURL = URL.createObjectURL(file.file);
            const image = new Image();
            image.onload = async () => {
                const canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0);

                // Use original image dimensions as default page size
                let pdfWidth = image.width;
                let pdfHeight = image.height;
                let orientation = image.width > image.height ? 'landscape' : 'portrait';

                // Optionally, override with selected page size
                switch (PageSize.value) {
                    case 'a3':
                        pdfWidth = 841.89;
                        pdfHeight = 1190.55;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'a4':
                        pdfWidth = 595.28;
                        pdfHeight = 841.89;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'a5':
                        pdfWidth = 419.53;
                        pdfHeight = 595.28;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'letter':
                        pdfWidth = 612;
                        pdfHeight = 792;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'legal':
                        pdfWidth = 612;
                        pdfHeight = 1008;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'b4':
                        pdfWidth = 708.66;
                        pdfHeight = 1000.63;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'b5':
                        pdfWidth = 498.9;
                        pdfHeight = 708.66;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    default:
                        // Use original image dimensions as default page size
                        pdfWidth = image.width;
                        pdfHeight = image.height;
                        orientation = image.width > image.height ? 'landscape' : 'portrait';
                        break;
                }

                const margin = parseInt(PageMargin.value);
                const availableWidth = pdfWidth - 2 * margin;
                const availableHeight = pdfHeight - 2 * margin;

                width.value = image.width;
                height.value = image.height;

                canvas.toBlob(async (blob) => {
                    const bytes = await blob.arrayBuffer();
                    const pdfDoc = await PDFDocument.create();
                    const page = pdfDoc.addPage([pdfWidth, pdfHeight]);

                    const pngImage = await pdfDoc.embedPng(bytes);

                    // Scale image to fit within the PDF page size while maintaining aspect ratio
                    const aspectRatio = image.width / image.height;
                    let newWidth = availableWidth;
                    let newHeight = availableHeight;

                    if (image.width > availableWidth || image.height > availableHeight) {
                        if (availableWidth / availableHeight > aspectRatio) {
                            newWidth = availableHeight * aspectRatio;
                            newHeight = availableHeight;
                        } else {
                            newWidth = availableWidth;
                            newHeight = availableWidth / aspectRatio;
                        }
                    } else {
                        newWidth = image.width;
                        newHeight = image.height;
                    }

                    // Center image on the page with margin
                    const xOffset = margin + (availableWidth - newWidth) / 2;
                    const yOffset = margin + (availableHeight - newHeight) / 2;

                    page.drawImage(pngImage, {
                        x: xOffset,
                        y: yOffset,
                        width: newWidth,
                        height: newHeight,
                    });

                    const pdfBytes = await pdfDoc.save();

                    const newFileName = file.name.replace(/\.[^/.]+$/, '') + '.pdf';
                    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

                    setTimeout(() => {
                        file.uploadProgress = 100;
                        file.uploading = false;
                        file.uploaded = true;
                        file.dataURL = URL.createObjectURL(pdfBlob);
                        file.convertedPdfUrl = file.dataURL;
                        convertedPdfUrl.value = file.dataURL;
                        file.name = newFileName;
                        URL.revokeObjectURL(objectURL);
                    }, 500);
                }, 'image/png');
            };

            image.src = objectURL;

            image.onerror = () => {
                console.error('Error loading image for conversion.');
                file.uploading = false;
                file.error = 'Error converting image';
            };
        };

        const uploadFilePngToPDF = async (file) => {
            file.uploading = true;
            file.uploadProgress = 0;
            disableConvertButton.value = false;
            const objectURL = URL.createObjectURL(file.file);
            const image = new Image();
            image.onload = async () => {
                const canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0);

                // Use original image dimensions as default page size
                let pdfWidth = image.width;
                let pdfHeight = image.height;
                let orientation = image.width > image.height ? 'landscape' : 'portrait';

                // Optionally, override with selected page size
                switch (PageSize.value) {
                    case 'a3':
                        pdfWidth = 841.89;
                        pdfHeight = 1190.55;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'a4':
                        pdfWidth = 595.28;
                        pdfHeight = 841.89;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'a5':
                        pdfWidth = 419.53;
                        pdfHeight = 595.28;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'letter':
                        pdfWidth = 612;
                        pdfHeight = 792;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'legal':
                        pdfWidth = 612;
                        pdfHeight = 1008;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'b4':
                        pdfWidth = 708.66;
                        pdfHeight = 1000.63;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'b5':
                        pdfWidth = 498.9;
                        pdfHeight = 708.66;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    default:
                        // Use original image dimensions as default page size
                        pdfWidth = image.width;
                        pdfHeight = image.height;
                        orientation = image.width > image.height ? 'landscape' : 'portrait';
                        break;
                }

                const margin = parseInt(PageMargin.value);
                const availableWidth = pdfWidth - 2 * margin;
                const availableHeight = pdfHeight - 2 * margin;

                width.value = image.width;
                height.value = image.height;

                canvas.toBlob(async (blob) => {
                    const bytes = await blob.arrayBuffer();
                    const pdfDoc = await PDFDocument.create();
                    const page = pdfDoc.addPage([pdfWidth, pdfHeight]);

                    const pngImage = await pdfDoc.embedPng(bytes);

                    // Scale image to fit within the PDF page size while maintaining aspect ratio
                    const aspectRatio = image.width / image.height;
                    let newWidth = availableWidth;
                    let newHeight = availableHeight;

                    if (image.width > availableWidth || image.height > availableHeight) {
                        if (availableWidth / availableHeight > aspectRatio) {
                            newWidth = availableHeight * aspectRatio;
                            newHeight = availableHeight;
                        } else {
                            newWidth = availableWidth;
                            newHeight = availableWidth / aspectRatio;
                        }
                    } else {
                        newWidth = image.width;
                        newHeight = image.height;
                    }

                    // Center image on the page with margin
                    const xOffset = margin + (availableWidth - newWidth) / 2;
                    const yOffset = margin + (availableHeight - newHeight) / 2;

                    page.drawImage(pngImage, {
                        x: xOffset,
                        y: yOffset,
                        width: newWidth,
                        height: newHeight,
                    });

                    const pdfBytes = await pdfDoc.save();

                    const newFileName = file.name.replace(/\.[^/.]+$/, '') + '.pdf';
                    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

                    setTimeout(() => {
                        file.uploadProgress = 100;
                        file.uploading = false;
                        file.uploaded = true;
                        file.dataURL = URL.createObjectURL(pdfBlob);
                        file.convertedPdfUrl = file.dataURL;
                        convertedPdfUrl.value = file.dataURL;
                        file.name = newFileName;
                        URL.revokeObjectURL(objectURL);
                    }, 500);
                }, 'image/png');
            };

            image.src = objectURL;

            image.onerror = () => {
                console.error('Error loading image for conversion.');
                file.uploading = false;
                file.error = 'Error converting image';
            };
        };

        const uploadFileWebpToPDF = async (file) => {
            file.uploading = true;
            file.uploadProgress = 0;
            disableConvertButton.value = false;
            const objectURL = URL.createObjectURL(file.file);
            const image = new Image();
            image.onload = async () => {
                const canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0);

                // Use original image dimensions as default page size
                let pdfWidth = image.width;
                let pdfHeight = image.height;
                let orientation = image.width > image.height ? 'landscape' : 'portrait';

                // Optionally, override with selected page size
                switch (PageSize.value) {
                    case 'a3':
                        pdfWidth = 841.89;
                        pdfHeight = 1190.55;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'a4':
                        pdfWidth = 595.28;
                        pdfHeight = 841.89;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'a5':
                        pdfWidth = 419.53;
                        pdfHeight = 595.28;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'letter':
                        pdfWidth = 612;
                        pdfHeight = 792;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'legal':
                        pdfWidth = 612;
                        pdfHeight = 1008;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'b4':
                        pdfWidth = 708.66;
                        pdfHeight = 1000.63;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'b5':
                        pdfWidth = 498.9;
                        pdfHeight = 708.66;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    default:
                        // Use original image dimensions as default page size
                        pdfWidth = image.width;
                        pdfHeight = image.height;
                        orientation = image.width > image.height ? 'landscape' : 'portrait';
                        break;
                }

                const margin = parseInt(PageMargin.value);
                const availableWidth = pdfWidth - 2 * margin;
                const availableHeight = pdfHeight - 2 * margin;

                width.value = image.width;
                height.value = image.height;

                canvas.toBlob(async (blob) => {
                    const bytes = await blob.arrayBuffer();
                    const pdfDoc = await PDFDocument.create();
                    const page = pdfDoc.addPage([pdfWidth, pdfHeight]);

                    const pngImage = await pdfDoc.embedPng(bytes);

                    // Scale image to fit within the PDF page size while maintaining aspect ratio
                    const aspectRatio = image.width / image.height;
                    let newWidth = availableWidth;
                    let newHeight = availableHeight;

                    if (image.width > availableWidth || image.height > availableHeight) {
                        if (availableWidth / availableHeight > aspectRatio) {
                            newWidth = availableHeight * aspectRatio;
                            newHeight = availableHeight;
                        } else {
                            newWidth = availableWidth;
                            newHeight = availableWidth / aspectRatio;
                        }
                    } else {
                        newWidth = image.width;
                        newHeight = image.height;
                    }

                    // Center image on the page with margin
                    const xOffset = margin + (availableWidth - newWidth) / 2;
                    const yOffset = margin + (availableHeight - newHeight) / 2;

                    page.drawImage(pngImage, {
                        x: xOffset,
                        y: yOffset,
                        width: newWidth,
                        height: newHeight,
                    });

                    const pdfBytes = await pdfDoc.save();

                    const newFileName = file.name.replace(/\.[^/.]+$/, '') + '.pdf';
                    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

                    setTimeout(() => {
                        file.uploadProgress = 100;
                        file.uploading = false;
                        file.uploaded = true;
                        file.dataURL = URL.createObjectURL(pdfBlob);
                        file.convertedPdfUrl = file.dataURL;
                        convertedPdfUrl.value = file.dataURL;
                        file.name = newFileName;
                        URL.revokeObjectURL(objectURL);
                    }, 500);
                }, 'image/png');
            };

            image.src = objectURL;

            image.onerror = () => {
                console.error('Error loading image for conversion.');
                file.uploading = false;
                file.error = 'Error converting image';
            };
        };

        const uploadFileJfifToJpg = async (file) => {
            file.uploading = true;
            file.uploadProgress = 0;

            const objectURL = URL.createObjectURL(file.file);
            const image = new Image();
            image.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0);

                let qualityValue = 1;

                if (quality.value === '0.5') {
                    qualityValue = 0.3;
                } else if (quality.value === '0') {
                    qualityValue = 0.1; // Low quality
                }

                canvas.toBlob((blob) => {
                    const newFileName = file.name.replace(/\.[^/.]+$/, "") + ".jpg"; // Replace existing extension with .jpg
                    const jpgFile = new File([blob], newFileName, { type: 'image/jpeg', lastModified: new Date() });

                    setTimeout(() => {
                        file.uploadProgress = 100;
                        file.uploading = false;
                        file.uploaded = true;

                        file.dataURL = URL.createObjectURL(jpgFile);
                        file.name = newFileName;
                        URL.revokeObjectURL(objectURL);
                    }, 500);
                }, 'image/jpeg', qualityValue);
            };
            image.src = objectURL;

            image.onerror = () => {
                console.error("Error loading image for conversion.");
                file.uploading = false;
                file.error = "Error converting image";
            };
        };

        const uploadFileJpegToJpg = async (file) => {
            file.uploading = true;
            file.uploadProgress = 0;

            const objectURL = URL.createObjectURL(file.file);
            const image = new Image();
            image.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0);

                let qualityValue = 1;

                if (quality.value === '0.5') {
                    qualityValue = 0.3;
                } else if (quality.value === '0') {
                    qualityValue = 0.1; // Low quality
                }

                canvas.toBlob((blob) => {
                    const newFileName = file.name.replace(/\.[^/.]+$/, "") + ".jpg"; // Replace existing extension with .jpg
                    const jpgFile = new File([blob], newFileName, { type: 'image/jpeg', lastModified: new Date() });

                    setTimeout(() => {
                        file.uploadProgress = 100;
                        file.uploading = false;
                        file.uploaded = true;

                        file.dataURL = URL.createObjectURL(jpgFile);
                        file.name = newFileName;
                        URL.revokeObjectURL(objectURL);
                    }, 500);
                }, 'image/jpeg', qualityValue);
            };
            image.src = objectURL;

            image.onerror = () => {
                console.error("Error loading image for conversion.");
                file.uploading = false;
                file.error = "Error converting image";
            };
        };

        const uploadFileHeifToJpg = async (file) => {
            file.uploading = true;
            file.uploadProgress = 0;

            const objectURL = URL.createObjectURL(file.file);
            const image = new Image();
            image.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0);

                let qualityValue = 1;

                if (quality.value === '0.5') {
                    qualityValue = 0.3;
                } else if (quality.value === '0') {
                    qualityValue = 0.1; // Low quality
                }

                canvas.toBlob((blob) => {
                    const newFileName = file.name.replace(/\.[^/.]+$/, "") + ".jpg"; // Replace existing extension with .jpg
                    const jpgFile = new File([blob], newFileName, { type: 'image/jpeg', lastModified: new Date() });

                    setTimeout(() => {
                        file.uploadProgress = 100;
                        file.uploading = false;
                        file.uploaded = true;

                        file.dataURL = URL.createObjectURL(jpgFile);
                        file.name = newFileName;
                        URL.revokeObjectURL(objectURL);
                    }, 500);
                }, 'image/jpeg', qualityValue);
            };
            image.src = objectURL;

            image.onerror = () => {
                console.error("Error loading image for conversion.");
                file.uploading = false;
                file.error = "Error converting image";
            };
        };

        const uploadFileHeifToSVG = async (file) => {
            file.uploading = true;
            file.uploadProgress = 0;

            try {
                const reader = new FileReader();
                reader.onload = () => {
                    const img = new Image();
                    img.onload = () => {
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        canvas.width = img.width;
                        canvas.height = img.height;

                        ctx.drawImage(img, 0, 0, img.width, img.height);

                        let qualityValue = 1;

                        if (quality.value === '0.5') {
                            qualityValue = 0.3;
                        } else if (quality.value === '0') {
                            qualityValue = 0.1; // Low quality
                        }
                        const dataURL = canvas.toDataURL('image/jpeg', qualityValue);

                        const svgString = `<svg width="${img.width}" height="${img.height}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><image xlink:href="${dataURL}" width="${img.width}" height="${img.height}" /></svg>`;

                        const svgBlob = new Blob([svgString], { type: 'image/svg+xml' });

                        setTimeout(() => {
                            file.uploadProgress = 100;
                            file.uploading = false;
                            file.uploaded = true;

                            file.dataURL = URL.createObjectURL(svgBlob);
                            file.name = file.name.replace(/\.[^/.]+$/, "") + ".svg";

                            console.log('Quality value after conversion:', quality.value);
                        }, 500);
                    };
                    img.src = reader.result;
                };
                reader.readAsDataURL(file.file);
            } catch (error) {
                console.error("Error converting image:", error);
                file.uploading = false;
                file.error = "Error converting image";
            }
        };

        const uploadFileHeifToPng = async (file) => {
            file.uploading = true;
            file.uploadProgress = 0;

            const objectURL = URL.createObjectURL(file.file);
            const image = new Image();
            image.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0);

                let qualityValue = 1;

                if (quality.value === '0.5') {
                    qualityValue = 0.3;
                } else if (quality.value === '0') {
                    qualityValue = 0.1; // Low quality
                }

                canvas.toBlob((blob) => {
                    const newFileName = file.name.replace(/\.[^/.]+$/, "") + ".png"; // Replace existing extension with .jpg
                    const jpgFile = new File([blob], newFileName, { type: 'image/jpeg', lastModified: new Date() });

                    setTimeout(() => {
                        file.uploadProgress = 100;
                        file.uploading = false;
                        file.uploaded = true;

                        file.dataURL = URL.createObjectURL(jpgFile);
                        file.name = newFileName;
                        URL.revokeObjectURL(objectURL);
                    }, 500);
                }, 'image/jpeg', qualityValue);
            };
            image.src = objectURL;

            image.onerror = () => {
                console.error("Error loading image for conversion.");
                file.uploading = false;
                file.error = "Error converting image";
            };
        };

        const uploadFileHeifToPDF = async (file) => {
            file.uploading = true;
            file.uploadProgress = 0;
            disableConvertButton.value = false;
            const objectURL = URL.createObjectURL(file.file);
            const image = new Image();
            image.onload = async () => {
                const canvas = document.createElement('canvas');
                canvas.width = image.width;
                canvas.height = image.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(image, 0, 0);

                // Use original image dimensions as default page size
                let pdfWidth = image.width;
                let pdfHeight = image.height;
                let orientation = image.width > image.height ? 'landscape' : 'portrait';

                // Optionally, override with selected page size
                switch (PageSize.value) {
                    case 'a3':
                        pdfWidth = 841.89;
                        pdfHeight = 1190.55;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'a4':
                        pdfWidth = 595.28;
                        pdfHeight = 841.89;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'a5':
                        pdfWidth = 419.53;
                        pdfHeight = 595.28;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'letter':
                        pdfWidth = 612;
                        pdfHeight = 792;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'legal':
                        pdfWidth = 612;
                        pdfHeight = 1008;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'b4':
                        pdfWidth = 708.66;
                        pdfHeight = 1000.63;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    case 'b5':
                        pdfWidth = 498.9;
                        pdfHeight = 708.66;
                        if (PageOrientation.value === 'landscape') {
                            [pdfWidth, pdfHeight] = [pdfHeight, pdfWidth];
                        } else {
                            orientation = 'portrait';
                        }
                        break;
                    default:
                        // Use original image dimensions as default page size
                        pdfWidth = image.width;
                        pdfHeight = image.height;
                        orientation = image.width > image.height ? 'landscape' : 'portrait';
                        break;
                }

                const margin = parseInt(PageMargin.value);
                const availableWidth = pdfWidth - 2 * margin;
                const availableHeight = pdfHeight - 2 * margin;

                width.value = image.width;
                height.value = image.height;

                canvas.toBlob(async (blob) => {
                    const bytes = await blob.arrayBuffer();
                    const pdfDoc = await PDFDocument.create();
                    const page = pdfDoc.addPage([pdfWidth, pdfHeight]);

                    const pngImage = await pdfDoc.embedPng(bytes);

                    // Scale image to fit within the PDF page size while maintaining aspect ratio
                    const aspectRatio = image.width / image.height;
                    let newWidth = availableWidth;
                    let newHeight = availableHeight;

                    if (image.width > availableWidth || image.height > availableHeight) {
                        if (availableWidth / availableHeight > aspectRatio) {
                            newWidth = availableHeight * aspectRatio;
                            newHeight = availableHeight;
                        } else {
                            newWidth = availableWidth;
                            newHeight = availableWidth / aspectRatio;
                        }
                    } else {
                        newWidth = image.width;
                        newHeight = image.height;
                    }

                    // Center image on the page with margin
                    const xOffset = margin + (availableWidth - newWidth) / 2;
                    const yOffset = margin + (availableHeight - newHeight) / 2;

                    page.drawImage(pngImage, {
                        x: xOffset,
                        y: yOffset,
                        width: newWidth,
                        height: newHeight,
                    });

                    const pdfBytes = await pdfDoc.save();

                    const newFileName = file.name.replace(/\.[^/.]+$/, '') + '.pdf';
                    const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

                    setTimeout(() => {
                        file.uploadProgress = 100;
                        file.uploading = false;
                        file.uploaded = true;
                        file.dataURL = URL.createObjectURL(pdfBlob);
                        file.convertedPdfUrl = file.dataURL;
                        convertedPdfUrl.value = file.dataURL;
                        file.name = newFileName;
                        URL.revokeObjectURL(objectURL);
                    }, 500);
                }, 'image/png');
            };

            image.src = objectURL;

            image.onerror = () => {
                console.error('Error loading image for conversion.');
                file.uploading = false;
                file.error = 'Error converting image';
            };
        };

        const confirmCrop = async () => {
            const dataURL = cropper.getDataURL();
            console.log("Cropped Image Data URL:", dataURL);

            // Update the Data URL and the file object in the files array
            const croppedBlob = dataURLtoBlob(dataURL);
            const newFileName = files.value[currentFileIndex.value].name.replace(/\.[^/.]+$/, '') + '_cropped.png';

            files.value[currentFileIndex.value].dataURL = dataURL;
            files.value[currentFileIndex.value].file = new File([croppedBlob], newFileName, { type: croppedBlob.type });
            files.value[currentFileIndex.value].name = newFileName;

            cropperPopup.value = false;
        };

        const dataURLtoBlob = (dataURL) => {
            const byteString = atob(dataURL.split(',')[1]);
            const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];

            const arrayBuffer = new ArrayBuffer(byteString.length);
            const intArray = new Uint8Array(arrayBuffer);

            for (let i = 0; i < byteString.length; i++) {
                intArray[i] = byteString.charCodeAt(i);
            }

            return new Blob([intArray], { type: mimeString });
        };

        const CropperPopupSet = (index) => {
            currentFileIndex.value = index;
            cropperPopup.value = true;
            pic.value = files.value[currentFileIndex.value].dataURL;
            console.log(files.value[currentFileIndex.value].dataURL);
        };

        const uploadFileOld = async (file) => {
            const formData = new FormData();
            formData.append("convert", 'to-jpg');
            formData.append("images", file.file); // Append the actual File object

            const config = {
                headers: {"Content-Type": "multipart/form-data"},
                onUploadProgress: (progressEvent) => {
                    const progress = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                    file.uploading = true;
                    file.uploadProgress = progress;
                },
            };

            try {
                const response = await axios.post("/file-upload-convert", formData, config);
                file.uploading = false;
                file.uploaded = true;
                file.uploadProgress = 100;
                file.responseData = response.data;
                file.downloadPath = response.data.path;
            } catch (error) {
                file.uploading = false;
                file.error = "Error uploading image";
                console.error("Error uploading image:", error);
            }
        };

        const dragover = async () => {
            dragActive.value = true;
        };
        const dragleave = async (event) => {
            dragActive.value = false;
        };
        const handleUpload = async (event) => {
            event.preventDefault();
            const isImage = await checkImageURL(fileUrl.value);
            if (!isImage) {
                alert('Invalid format: Only image URLs are allowed.');
                return;
            }

            try {
                const response = await fetch(fileUrl.value);
                if (!response.ok) {
                    console.error('Error:', response.status, response.statusText);
                    alert('Error fetching image from the URL.');
                    return;
                }

                const blob = await response.blob();
                const file = new File([blob], fileUrl.value.substring(fileUrl.value.lastIndexOf('/') + 1), { type: blob.type });

                const imageUrl = URL.createObjectURL(blob);
                const fileObject  = {
                    dataURL: imageUrl,
                    name: file.name,
                    type: blob.type,
                    uploading: false,
                    uploaded: false,
                    uploadProgress: 0,
                    error: null,
                    file: file,
                };
                files.value.push(fileObject);
                disableConvertButton.value = false;
            } catch (error) {
                console.error('Error handling image upload:', error);
            }
        };

        const checkImageURL = async (url) => {
            console.log('Checking image URL:', url);
            const response = await fetch(url);
            console.log('Fetch response:', response);
            if (!response.ok) {
                console.error('Error:', response.status, response.statusText);
                throw new Error('Failed to fetch image.');
            }

            const contentType = response.headers.get('content-type');
            console.log('Content-Type:', contentType);
            if (contentType && (contentType.startsWith('image/') || contentType.startsWith('img/'))) {

                const imageData = await response.blob();
                console.log('Image Data:', imageData);
                if (imageData && imageData.type.startsWith('image/')) {
                    console.log('Image URL is valid.');
                    return true;
                } else {
                    throw new Error('Response data is not an image.');
                }
            } else {
                throw new Error('Invalid content type for image.');
            }
        };

        const mergeAllPdfs = async () => {
            const pdfsToMerge = await Promise.all(files.value.map(async (file) => {
                const response = await fetch(file.dataURL);
                const arrayBuffer = await response.arrayBuffer();
                return arrayBuffer;
            }));

            const mergedPdfFile = await mergePdfs(pdfsToMerge);
            const blob = new Blob([mergedPdfFile], { type: 'application/pdf' });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'merged.pdf';

            // Append the link to the document, click it to trigger the download, and then remove it
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);

            // Clean up the object URL
            URL.revokeObjectURL(url);
        };
        const mergePdfs = async (pdfsToMerge) => {
            const mergedPdf = await PDFDocument.create();
            const actions = pdfsToMerge.map(async pdfBuffer => {
                const pdf = await PDFDocument.load(pdfBuffer);
                const copiedPages = await mergedPdf.copyPages(pdf, pdf.getPageIndices());
                copiedPages.forEach((page) => {
                    mergedPdf.addPage(page);
                });
            });
            await Promise.all(actions);
            const mergedPdfFile = await mergedPdf.save();
            return mergedPdfFile;
        };

        const dropFile = (event) => {
            event.preventDefault();
            dragActive.value=false;
            const newFiles = Array.from(event.dataTransfer.files).map((file) => ({
                dataURL:URL.createObjectURL(file),
                name:file.name,
                uploading: false,
                uploaded: false,
                uploadProgress: 0,
                error: null,
                file: file, // Store the actual File object
            }));

            files.value.push(...newFiles);
        };
        return {
            dragover,
            dragleave,
            dropFile,
            remove,
            dragActive,
            submitted,
            files,
            handleFileSelection,
            uploadFiles,
            quality,
            settingPopup,
            PdfSettingPopup,
            cropperPopup,
            downloadAllAsZip,
            conversionCompleted,
            showDownloadAllButton,
            removeAllFiles,
            disableConvertButton,
            fileUrl,
            handleUpload,
            CropperPopupSet,
            confirmCrop,
            pic,
            filesUploaded,
            selectedFormat,
            formatOptions,
            PageSize,
            PageOrientation,
            PageMargin,
            width,
            height,
            convertedPdfUrl,
            handleFormatChange,
            mergeAllPdfs,
            loading,
        };
    },
};

</script>
